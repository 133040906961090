import _CLientPagesServiceAction from "cms/actions/services/ClientPagesServiceAction";


const screenCode = "205";
class ClientPagesServiceAction extends _CLientPagesServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientPagesServiceAction;
