import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientSliderItemService extends ReadOnlyOdataServiceBase {

    static code = "206";
    code = "206";

    constructor () {

        super({
            sourcePath: "client-slider-items"
        });

    }

}

export default ClientSliderItemService;
