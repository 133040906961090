import React from "react";
import { isEmpty, orderBy } from "lodash";
// components
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
// enums
import { EViewType } from "cms/enums/EViewType";
// types
import TRMDRAccordion from "../type";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


const RMDRAccordion01 = ( props:TRMDRAccordion ) => {
    console.log("RMDRAccordion01.rendered", props);

    if ( 
        !isEmpty(props.parentLanguageVersion) && props.parentLanguageVersion && 
        !isEmpty(props.parentLanguageVersion?.id) && props.parentLanguageVersion?.id && 
        props.parentLanguageVersion.viewType === EViewType.ACCORDION 
    ) {

        let payloadChild = props.languageVersions?.filter((item) => item.parentId === props.parentLanguageVersion?.id);

        if ( isEmpty(payloadChild) ) return null;

        payloadChild = orderBy(payloadChild, ['order'],['asc']);

        return (
            <div className="col-12 pt-4 pb-2">
                {
                    props.parentLanguageVersion.value &&
                        <div className="section-title clearfix ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.parentLanguageVersion.value)}} />
                }
                
                <Accordion>
                    {
                        payloadChild?.map(
                            (childItem) => (
                                <AccordionItem key={childItem.id}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><span className="tw-font-semibold">{childItem.name}</span></AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(childItem.value)}} />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        )
                    }
                </Accordion>
            </div>
        );

    }

    return null;
}

const Component = React.memo(RMDRAccordion01, ( prevProps:TRMDRAccordion, nextProps:TRMDRAccordion ) => {
    console.log("RMDRAccordion01.memo", { prevProps, nextProps });
    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.parentLanguageVersion !== nextProps.parentLanguageVersion
    );
});

export default Component;