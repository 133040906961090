import _ClientContentsServiceAction from "cms/actions/services/ClientContentsServiceAction";


const screenCode = "20B";
class ClientContentsServiceAction extends _ClientContentsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientContentsServiceAction;
