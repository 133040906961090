import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
// components
import RMDRImage from "components/RMDRImage";
// actions
import CoreAction from "core/actions/common/CoreAction";
import { Dispatch } from "@reduxjs/toolkit";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EView } from "cms/enums/EView";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


function mapStateToProps ( state: any ) {

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        siteSettingDetailPayload

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);

    return {

        _hideLoader: () => { 
            coreAction.hideLoader() 
        }
        
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TErrorBoundary<T> = {

} & T;


/* component */
function ErrorBoundary ( props:TErrorBoundary<PropsFromRedux> ) {

    const errorBoundaryHeader = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"errorBoundaryHeader"
            }
        ],
        type:"find"
    });

    const errorBoundaryBody = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"errorBoundaryBody"
            }
        ],
        type:"find"
    });

    const backToHomeString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"backToHomeString"
            }
        ],
        type:"find"
    });

    useEffect(
        () => {
            props._hideLoader();
        }, []
    )

    return (
        <div className="site-main">
            <section className="error-boundary">

                    <div className="row">
                        <div className="col-md-12">

                            <div style={{"textAlign": "center"}}>
                                <RMDRImage
                                    src="images/oops.png"
                                    alt="Error Boundary"
                                    title="Error Boundary"
                                />
                            </div>

                            <header className="page-header">
                                <h1 className="page-title">{errorBoundaryHeader?.value}</h1>
                            </header>
                            
                            <div className="page-content" >
                                <p className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(errorBoundaryBody?.value)}} />
                            </div>

                            <a
                                className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mb-15"
                                href={process.env.PUBLIC_URL + "/"}
                            >
                                {backToHomeString?.value}
                            </a>
                            
                        </div>
                    </div>

            </section>
        </div>
    );

};



const Component = React.memo(ErrorBoundary, ( prevProps:TErrorBoundary<PropsFromRedux>, nextProps:TErrorBoundary<PropsFromRedux> ) => {
    console.log("ErrorBoundary.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
