import _ClientMenuItemServiceAction from "cms/actions/services/ClientMenuItemServiceAction";


const screenCode = "201";
class ClientMenuItemServiceAction extends _ClientMenuItemServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);
    }

}

export default ClientMenuItemServiceAction;
