import EActionType from 'core/enums/EActionType';
import EMessageStatus from 'core/enums/EMessageStatus';
//
import IAction from 'core/interfaces/IAction';

/**
 * - Uygulama içinde herhangi bir sayfada popup mesajı gösterir.
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode ve serviceCode verisi empty olarak set edilmiştir.
 */

function PopupPublisherStore (state:object = {}, action:IAction) {

    switch (action.type) {

        // success
        case `${EActionType.PUBLISH_POPUP}${EMessageStatus.SUCCESS}`:
            // TODO: Popup Message
            return state;

        // default
        default:
            return state;
    
    }

}

export default PopupPublisherStore;
