import INNR_001 from "./components/INNR_001";
import INNR_002 from "./components/INNR_002";
import INNR_003 from "./components/INNR_003";
import INNR_004 from "./components/INNR_004";

const InnerLayouts:any = {

    INNR_001,
    INNR_002,
    INNR_003,
    INNR_004,

}

export default InnerLayouts;
