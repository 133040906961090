import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import ContentRenderer from "components/ContentRenderer";
import ContentInnerHeader from "components/ContentInnerHeader";
import { TINNRLayout } from "components/InnerLayouts/type";
// actions
import CoreAction from "core/actions/common/CoreAction";


function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);

    return {
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        }
    }

}

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR001 ( props: TINNRLayout<PropsFromRedux> ) {

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

            return function cleanup() {
                props._showLoader();
            }

        }, [ ]
    )

    return (
        <section className="ttm-row project-single-section clearfix">
            <div className="container-xl">
                <ContentInnerHeader payload={props.contentDetailPayload} />
                <ContentRenderer languageVersions={props.contentDetailPayload?.languageVersions} />
            </div>
        </section>
    );

}

const Component = React.memo(INNR001, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR001.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
