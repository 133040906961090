import { ReactNode, isValidElement } from "react";
import { isString } from "lodash";


type TItemBox = {

    title: string;
    value: string;
    icon?: string|ReactNode;
    className?: string;

}

function ItemBox ( props:TItemBox ) {

    function renderIcon () {

        if ( props.icon ) {
            if ( isString(props.icon) ) return <i className={props.icon} />
            else if ( isValidElement(props.icon) ) return props.icon;
            return null;
        }
        
        return null;

    }

    return (
        <div className={`quicklink-box ${props.className}`}>
            <div className="featured-icon-box left-icon">
                <div className="featured-icon">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                        { renderIcon() }
                    </div>
                </div>
                <div className="featured-content">
                    <div className="featured-desc">
                        <p>{props.title}</p>
                    </div>
                    <div className="featured-title">
                        <h5>{ props.value }</h5>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ItemBox;
export type {
    TItemBox
};
