import { useEffect, useState, CSSProperties } from "react";
import { isEmpty } from "lodash";
import CoreDefaults from 'core/Defaults';
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import ESizeType from "cms/enums/ESizeType";
import EMimeType from "cms/enums/EMimeType";
// interfaces
import IResource from 'cms/interfaces/IResource';
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// image
import defaultImage from "assets/img/default-image.webp";


type TRMDRImage = {

    id?:string;
    src?: string;

    resources?: IResource[];
    
    resourceType?: EResourceType;
    sizeType?: ESizeType;

    alt?: string|null;
    title?: string|null;

    style?: CSSProperties;
    className?: string; 

    width?: number|string;
    height?: number|string;

    disableLazyLoading?:boolean;

}

function RMDRImage ( props:Readonly<TRMDRImage> ) {

    const [ payload, setPayload ] = useState<any>(null);

    useEffect(
        () => {
            
            const applicationDocument:IApplicationDocument|null|undefined = ApplicationDocumentSelector({ 
                resources: props.resources,
                resourceType: props.resourceType,
                sizeType: props.sizeType
            });

            if ( !isEmpty(applicationDocument) && applicationDocument ) {

                const imageURL = `${CoreDefaults.cdn}/${applicationDocument.filePath}`;
                if ( applicationDocument.contentType === EMimeType.SVG.name ) {

                    let _imageSource:any = null;
                    let _isError:boolean = false;

                    fetch(
                        imageURL, 
                        { method: "GET", mode: 'cors' }
                    )
                    .then(res => res.text())
                    .then((value:any) => _imageSource = value)
                    .catch(() => _isError = true)
                    .then(() => setPayload({ imageSource: _imageSource, alt:applicationDocument.name, contentType: applicationDocument.contentType, isLoaded: true, _isError: _isError }))
                    
                }
                else {

                    setPayload({ imageSource: imageURL, contentType: applicationDocument.contentType, isLoaded: true, isError: false});

                }

            }

        }, [ props.resources ]
    );

    function replaceImage (error:any) {
    
        error.target.path = defaultImage;
    
    }

    if ( payload?.imageSource && !isEmpty(payload?.imageSource) ) {
        if ( payload.contentType === EMimeType.SVG.name ) {
            if ( payload.imageSource && payload.isLoaded && !payload.isErrored ) {
                return <div id={props.id} style={props.style} className={props.className} dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(payload.imageSource) }} title={props.title||""} />;
            }
        }
        else {
            return <img id={props.id} src={payload.imageSource} alt={props.alt||payload.alt} style={props.style} className={props.className} width={props.width||"100%"} height={props.height||"100%"} onError={replaceImage} loading={props.disableLazyLoading?"eager":"lazy"} title={props.title||""} />
        }
    }
    else if ( props?.src && !isEmpty(props?.src) ) {

        return <img id={props.id} src={props.src} alt={props.alt||""} style={props.style} className={props.className} width={props.width||"100%"} height={props.height||"100%"} onError={replaceImage} loading={props.disableLazyLoading?"eager":"lazy"} title={props.title||""} />

    }

    return null;

}

export default RMDRImage;
export type {
    TRMDRImage
};
