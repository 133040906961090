import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientContentsService extends ReadOnlyOdataServiceBase {

    static code = "201";
    code = "201";

    constructor () {

        super({
            sourcePath: "client-contents"
        });

    }

}

export default ClientContentsService;
