const EViewTypeDataSource = {
    
    TEXT                : { id: 1, name: "Text" },
    TAB                 : { id: 2, name: "Tab" },
    ACCORDION           : { id: 3, name: "Accordion" },
    NAME_DESCRIPTION    : { id: 4, name: "Name and Description" }

}

enum EViewType {
    
    TEXT = 1,
    TAB,
    ACCORDION,
    NAME_DESCRIPTION

}

export default EViewTypeDataSource;
export {

    EViewType

}
