import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientCategoriesService extends ReadOnlyOdataServiceBase {

    static code = "200";
    code = "200";

    constructor () {
        super({
            sourcePath: "client-categories"
        });
    }

}

export default ClientCategoriesService;
