import React from "react";
// types
import { TMainPageLayout } from "../type";
// components
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import LatestContents01 from "components/LatestContents/components/LatestContents01";


function mapStateToProps ( state: any ) {
    console.log("MNPG011.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG011 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG011.rendered", props)

    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
            <LatestContents01
                payload={props.parentPayload}
            />
        </div>
    );

}

const Component = React.memo(MNPG011, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG011.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
