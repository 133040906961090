import OdataCoreServiceBase from 'core/services/OdataCoreServiceBase';


class LanguageService extends OdataCoreServiceBase {
	
    static code = "004";
    code = "004";

	constructor () {
		super({
			sourcePath: "client-languages"
		});
	}
	
}

export default LanguageService;
