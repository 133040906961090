enum EActionStatus {

    SUCCESS        = "SU",
    FAIL           = "FA",
    STORE          = "ST",
    MODIFIER       = "MO",
    
    CLEAN          = "CL",
    CLEAN_MESSAGE  = "CM",
    CLEAN_PAYLOAD  = "CP",
    CLEAN_ERROR    = "CE",
    CLEAN_COUNT    = "CC",
    
    FETCHING       = "FE",
    TOGGLE         = "TO",

}

export default EActionStatus;
