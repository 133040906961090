import BaseReduxStore from 'core/reducers/BaseReduxStore';
// interface
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';
import ICoreReduxStoreBase from 'core/interfaces/ICoreReduxStoreBase';
// enum
import EActionType from 'core/enums/EActionType';
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


class CoreReduxStore {

    serviceCode: string = "";
    screenCode: string = "";

    constructor({ screenCode, serviceCode }: ICoreReduxStore ) {
        this.serviceCode = serviceCode;
        this.screenCode = screenCode;
    }


    BASE ( {storeKey, protocolKey, initialState = EReduxStoreInitialState.DEFAULT}: ICoreReduxStoreBase ) {
        return {
            [storeKey]: BaseReduxStore({ 
                screenCode: this.screenCode,
                serviceCode: this.serviceCode, 
                protocol: protocolKey,
                initialState: initialState
            }) 
        }
    }

    
    LIST () {
        return this.BASE({ 
            storeKey: "list", 
            protocolKey: EActionType.LIST,
            initialState: EReduxStoreInitialState.ARRAY
        });
    }

    POST ( initialState = EReduxStoreInitialState.OBJECT ) {
        return this.BASE({ 
            storeKey: "post", 
            protocolKey: EActionType.POST, 
            initialState: initialState
        });
    }

    PATCH () {
        return this.BASE({ 
            storeKey: "patch", 
            protocolKey: EActionType.PATCH, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    MANAGEMENT ( initialState = EReduxStoreInitialState.OBJECT ) {
        return this.BASE({ 
            storeKey: "management", 
            protocolKey: EActionType.MANAGEMENT, 
            initialState: initialState
        });
    }

    DELETE () {
        return this.BASE({ 
            storeKey: "delete", 
            protocolKey: EActionType.DELETE, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    DETAILS () {
        return this.BASE({ 
            storeKey: "details", 
            protocolKey: EActionType.DETAILS, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    STORAGE ( initialState = EReduxStoreInitialState.OBJECT ) {
        return this.BASE({ 
            storeKey: "storage", 
            protocolKey: EActionType.STORAGE, 
            initialState: initialState
        });
    }

    GET () {
        return this.BASE({ 
            storeKey: "get", 
            protocolKey: EActionType.GET,
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    SIGNIN () {
        return { 
            "signIn": BaseReduxStore({ 
                screenCode: "",
                serviceCode: "", 
                protocol: EActionType.SIGNIN,
                initialState: EReduxStoreInitialState.OBJECT
            })
        }
    }

    DOWNLOAD () {
        return this.BASE({ 
            storeKey: "download", 
            protocolKey: EActionType.DOWNLOAD, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    UPLOAD () {
        return this.BASE({ 
            storeKey: "upload", 
            protocolKey: EActionType.UPLOAD, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }
    
    
    CHANGE () {
        return this.BASE({ 
            storeKey: "change", 
            protocolKey: EActionType.CHANGE, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    SEARCH () {
        return this.BASE({ 
            storeKey: "search", 
            protocolKey: EActionType.SEARCH, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

    
    PUBLISH_MESSAGE () {
        return this.BASE({ 
            storeKey: "publishMessage", 
            protocolKey: EActionType.PUBLISH_MESSAGE, 
            initialState: EReduxStoreInitialState.OBJECT 
        });
    }

    PUBLISH_POPUP () {
        return this.BASE({ 
            storeKey: "publishPopup", 
            protocolKey: EActionType.PUBLISH_POPUP, 
            initialState: EReduxStoreInitialState.OBJECT
        });
    }

}

export default CoreReduxStore;
