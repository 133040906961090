import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientMainPageItemsService extends ReadOnlyOdataServiceBase {

    static code = "202";
    code = "202";

    constructor () {
        super({
            sourcePath: "client-main-page-items"
        });
    }

}

export default ClientMainPageItemsService;
