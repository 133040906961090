// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientSiteSettingsService from "cms/services/ClientSiteSettingsService";


class ClientSiteSettingsServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientSiteSettingsService, screenCode);
    }

}

export default ClientSiteSettingsServiceAction;
