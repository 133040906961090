import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientContentsService from 'cms/services/ClientContentsService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientContentsServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientContentsService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.DETAILS()
    
    });
}

export default ClientContentsServiceStore;
