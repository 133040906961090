const EMimeType = {

    AVIF:   { id: 1, name: "image/avif" },
    BMP:    { id: 2, name: "image/bmp" },
    GIF:    { id: 3, name: "image/gif" },
    JPEG:   { id: 4, name: "image/jpeg" },
    JPG:    { id: 5, name: "image/jpg" },
    PNG:    { id: 6, name: "image/png" },
    SVG:    { id: 7, name: "image/svg+xml" },
    TIFF:   { id: 8, name: "image/tiff" },
    WEBP:   { id: 9, name: "image/webp" }

}

export default EMimeType;
