import React from "react";
import Slider from 'react-slick';
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// types
import { TMainPageLayout } from "../type";
// components
import RMDRImage from "components/RMDRImage";
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
// enums
import { EResourceType } from "cms/enums/EResourceType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// utilities
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import RMDRLink from "components/RMDRLink";



const sliderSetting = {
    "dots": false,
    "arrow": false,
    "autoplay": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 4,
    "slidesToScroll": 1,

    "responsive": [
        {
            "breakpoint": 1199,
            "settings": { "slidesToShow": 5, "slidesToScroll": 5 }
        },
        {
            "breakpoint": 1024,
            "settings": { "slidesToShow": 3, "slidesToScroll": 3 }
        },
        {
            "breakpoint": 767,
            "settings": { "slidesToShow": 2, "slidesToScroll": 2 }
        },
        {
            "breakpoint": 480,
            "settings": { "slidesToShow": 1, "slidesToScroll": 1 }
        }
    ]
}

function mapStateToProps ( state: any ) {
    console.log("MNPG006.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG006 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG006.rendered", props) 

    return (
        <section className="ttm-row clearfix">
            <div className="container-xl">

                <div className="row">
                    <div className="col-12 text-center">

                        <Slider className="row slick_slider ttm-boxes-spacing-30px" {...sliderSetting} slidesToShow={props.childsPayload.length} arrows={false}>
                            {
                                props.childsPayload?.map((item, index) => {

                                    const childLanguageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                        languageVersions: item.languageVersions,
                                        filters: [
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                        ],
                                        type:'find'
                                    });

                                    const redirectUrl =  LanguageVersionSelector<ILanguageVersionList|undefined>({
                                        languageVersions: item.languageVersions,
                                        filters: [
                                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                        ],
                                        type: "find"
                                    });

                                    return (
                                        <div key={item.id} className="ttm-box-col-wrapper">
                                            <div className="client-box ttm-box-view-boxed-logo">
                                                <div className="client">
                                                    <div className="ttm-client-logo-tooltip" data-tooltip={childLanguageVersion?.name}>
                                                        <RMDRLink
                                                            title={childLanguageVersion?.name}
                                                            hasNavigationDestination={item.hasDestinationNavigation}
                        
                                                            navigationURL={childLanguageVersion?.contentRouteNavigationURL}
                        
                                                            redirectType={item.redirectType}
                                                            redirectUrl={redirectUrl?.value}
                                                            isShowAlways
                                                        >
                                                            <RMDRImage
                                                                resourceType={EResourceType.OUTER_IMAGE} 
                                                                resources={item.resources}
                                                                alt={childLanguageVersion?.name} 
                                                                className="img-fluid"
                                                                title={childLanguageVersion?.name}
                                                            />
                                                        </RMDRLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    
                    </div>
                </div>

            </div>
        </section>
    )

}

const Component = React.memo(MNPG006, (prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux>) => {
    console.log("MNPG006.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
