import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from '@reduxjs/toolkit';
import { isEmpty } from "lodash";
import { v4 as UUIDv4 } from 'uuid';
// components
import RMDRLink from "components/RMDRLink";
// types
import { TLatestContents } from "../type";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from 'cms/enums/EContentStatus';
// actions
import ClientPagesServiceAction from "cms/actions/components/LatestContents/ClientPagesServiceAction"
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import IClientPageList from 'cms/interfaces/IClientPageList';
import IClientLanguage from 'core/interfaces/IClientLanguage';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
// utilities
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import TValidations from 'cms/types/TValidations';


function mapStateToProps ( state: any ) {
    console.log("LatestContents02.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail   = state.SiteSettingStore.details?.payload;

    const clientPagesGet:any                                    = state.LatestContents.ClientPagesStore.get;
    const clientPagesIsLoading:boolean                          = state.LatestContents.ClientPagesStore.isLoading;
    const clientPagesError:TValidations[]|null|undefined        = state.LatestContents.ClientPagesStore.error;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
        
        clientPagesGet,
        clientPagesIsLoading,
        clientPagesError

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listClientPages: ( categoryId:string, webApplicationLanguageCode:string, top:number, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `contentId eq ${categoryId}`],
                orderBy: ["order asc"],
                top: top
            };

            clientPagesServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode }, 
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } });
        },

        // others
        _clean: ( key:string ) => {
            clientPagesServiceAction.getClean(key);
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function Loader () {

    return (
        <div className="widget widget_text clearfix tw-animate-pulse">
            <div className="widget-title tw-h-8 tw-w-full tw-bg-gray-100" />
            <ul className="widget-post tw-h-full">
                <li>
                    <div className='tw-h-4 tw-w-full tw-bg-gray-100' />
                </li>
                <li>
                    <div className='tw-h-4 tw-w-full tw-bg-gray-100' />
                </li>
                <li>
                    <div className='tw-h-4 tw-w-full tw-bg-gray-100' />
                </li>
                <li>
                    <div className='tw-h-4 tw-w-full tw-bg-gray-100' />
                </li>
            </ul>
        </div>
    )

}

function LatestContents02 ( props:TLatestContents<PropsFromRedux> ) {
    console.log("LatestContents02.rendered: ", props);

    const [ payload, setPayload ] = useState<IClientPageList[]>([]);
    const [ key, setKey ] = useState<string>();

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({ 
        languageVersions: props.payload?.languageVersions, 
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect(
        () => {

            if ( !isEmpty(props.payload) && props.payload.isContentChildsMapped === true ) {
                let _key = UUIDv4().split("-")[4];
                setKey(_key);
                props._listClientPages(props.payload.contentId, props.webApplicationLanguageCode, props.top||5, _key);
            }

        }, []
    )

    useEffect(
        () => {

            if ( key && !isEmpty(props.clientPagesGet?.[key]) ) {
                setPayload(props.clientPagesGet?.[key]);
                setTimeout(() => { props._clean(key); }, 100);
            }

        }, [ props.clientPagesGet, key ]
    )

    if ( props.clientPagesIsLoading ) {

        return <Loader />

    } else if ( isEmpty(props.clientPagesError) ) {
        
        return (
            <div className="widget link-widget clearfix">
                <h3 className="widget-title">{ languageVersion?.name }</h3>
                <ul id="menu-footer-services">
                    {
                        payload.map((item) => {
    
                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: 'find'
                            });
    
                            const redirectUrl = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                ],
                                type: 'find'
                            });
    
                            return (
                                <li key={item.id}>
                                    <RMDRLink
                                        title={languageVersion?.name}
                                        text={languageVersion?.name}
    
                                        hasNavigationDestination={props.payload.hasDestinationNavigation}
    
                                        navigationURL={languageVersion?.contentRouteNavigationURL}
    
                                        redirectType={props.payload.redirectType}
                                        redirectUrl={redirectUrl?.value}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>           
        );

    }

    return null;

}



const Component = React.memo(LatestContents02, ( prevProps:TLatestContents<PropsFromRedux>, nextProps:TLatestContents<PropsFromRedux> ) => {
    console.log("LatestContents02.memo", { prevProps, nextProps });
    return !(
        prevProps.clientPagesIsLoading !== nextProps.clientPagesIsLoading
        || prevProps.clientPagesGet !== nextProps.clientPagesGet 
    );
});

export default connector(Component);

//TODO: Is Content Childs Mapped var ise istek atılacak. Yok ise childlar listelenecek.
