import React from "react";
// types
import { TMainPageLayout } from "../type";
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { connect, ConnectedProps } from "react-redux";
import RMDRImage from "components/RMDRImage";
import { EResourceType } from "cms/enums/EResourceType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import { EContentType } from "cms/enums/EContentType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("MNPG001.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG001 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG001.rendered", props);
    
    return (
        <section className="ttm-row service-section position-relative z-1 clearfix">
            <div className="px-4 px-md-4 px-xl-5">

                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey p-0">
                    {
                        props.childsPayload?.map((item, index) => {
                            
                            const childLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type:"find"
                            });

                            return (
                                <div key={item.id} className="col-md-3">
                                    <div className={`featured-icon-box left-icon style1 ${index%2===0?"ttm-bgcolor-skincolor":"ttm-bgcolor-darkgrey"} ttm-textcolor-white h-100`}>
                                        
                                        <div className="featured-icon">
                                            <RMDRImage
                                                resourceType={EResourceType.ICON} 
                                                resources={item.resources} 
                                                alt={childLanguageVersion?.name} 
                                                title={childLanguageVersion?.name} 
                                                className={`ttm-icon ${index%2===0?"ttm-bgcolor-darkgrey":"ttm-bgcolor-skincolor"} ttm-icon_element-size-md`}
                                            />
                                        </div>
                                        
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5 className="tw-line-clamp-2" title={childLanguageVersion?.name||""}>{ childLanguageVersion?.name }</h5>
                                            </div>
                                            {/* <div className="featured-desc ck-content tw-line-clamp-2" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(childLanguageVersion?.value)}} /> */}
                                        </div>
                                    </div>
                                </div>
                            );

                        })
                    }
                </div>
                
            </div>
        </section>
    );

}

const Component = React.memo(MNPG001, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG001.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);