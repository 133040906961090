import React from "react";
// types
import { TMainPageLayout } from "../type";
// components
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import RMDRImage from "components/RMDRImage";
import { EResourceType } from "cms/enums/EResourceType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


function mapStateToProps ( state: any ) {
    console.log("MNPG005.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG005 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG005.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    return (
        <section className="ttm-row aboutus-section-style2 ttm-bgcolor-grey clearfix">
            <div className="container-xl">

                <div className="row no-gutters">

                    <div className="col-lg-6 res-991-text-center res-991-mb-40">
                        <div className="ttm_single_image-wrapper">
                            <RMDRImage
                                resourceType={EResourceType.OUTER_IMAGE} 
                                resources={props.parentPayload?.resources}
                                alt={parentLanguageVersion?.name} 
                                className="img-fluid"
                                title={parentLanguageVersion?.name}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="spacing-4 ttm-bgcolor-grey ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(parentLanguageVersion?.value)}} />
                    </div>

                </div>

            </div>
        </section>
    )

}

const Component = React.memo(MNPG005, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG005.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
