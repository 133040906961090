import { combineReducers } from 'redux';
// reducers
import ClientMenuItemServiceStore from "cms/reducers/services/ClientMenuItemServiceStore";


const screenCode = "201";
const HeaderStore = combineReducers({
 
    ClientMenuItemStore: ClientMenuItemServiceStore({ screenCode }),

});

export default HeaderStore;
