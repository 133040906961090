import React, { useEffect, useState } from "react";
import { isEmpty, reject } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
import ContentRenderer from "components/ContentRenderer";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/components/ShowroomLayouts/ClientPagesServiceAction";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientPageList from "cms/interfaces/IClientPageList";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientPageDetail from "cms/interfaces/IClientPageDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// types
import TValidations from "cms/types/TValidations";
import { TSHWRMLayout } from "../type";


function mapStateToProps ( state: any ) {
    console.log("SHWRM004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const pageListPayload:IClientPageList[]|null    = state.ShowroomLayoutsStore.ClientPagesStore.list?.payload;
    const pageListError:TValidations[]|null         = state.ShowroomLayoutsStore.ClientPagesStore.list?.error;
    const pageListIsLoading:boolean                 = state.ShowroomLayoutsStore.ClientPagesStore.list?.isLoading;

    const pageDetailsPayload:IClientPageDetail|null = state.ShowroomLayoutsStore.ClientPagesStore.details?.payload;
    const pageDetailsError:TValidations[]|null      = state.ShowroomLayoutsStore.ClientPagesStore.details?.error;
    const pageDetailsIsLoading:boolean              = state.ShowroomLayoutsStore.ClientPagesStore.details?.isLoading;

    const errors = reject( [pageListError, pageDetailsError], isEmpty )[0] || [];
    const isLoading = [pageListIsLoading, pageDetailsIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        pageListPayload,
        pageDetailsPayload,

        errors,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listPages: ( contentId:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`contentId eq ${contentId}`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 100
            };

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        _listModifier: ( match_key:string, match_value:string, key:string|null, value:any ) => {
            clientPagesServiceAction.listModifier({
                payload: [ { match_key, match_value, key, value} ]
            }); 
        },

        // detailss
        _detailsPage: ( categoryId:string, webApplicationLanguageCode:string ) => {
            clientPagesServiceAction.details({ servicePayload: { key: categoryId, language: webApplicationLanguageCode }});
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
            clientPagesServiceAction.detailsClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function SHWRM004 ( props:TSHWRMLayout<PropsFromRedux> ) {
    console.log("SHWRM004.rendered: ", props);
    
    const [ pageIndex, setPageIndex ] = useState<number>(-1);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({ 
        languageVersions:props.contentDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    useEffect(
        () => {

            props._listPages( props.contentDetailPayload.id, props.webApplicationLanguageCode );

            return function cleanup () {
                props._showLoader();
                props._clean();
            }
            
        }, []
    )

    useEffect (
        () => {
            if ( !isEmpty(props.pageListPayload) && pageIndex === -1 && !props.isLoading ) {
                setPageIndex(0);
            }
        }, [ props.pageListPayload ]
    )

    useEffect (
        () => {
            if ( !isEmpty(props.pageDetailsPayload) && !props.isLoading ) {
                props._listModifier("id", props.pageDetailsPayload.id, "languageVersions", props.pageDetailsPayload.languageVersions)
                setPageIndex((prevState) => prevState += 1);
            }
        }, [ props.pageDetailsPayload ]
    )

    useEffect (
        () => {
            if ( pageIndex >= 0 ) {
                const pageId = props.pageListPayload?.[pageIndex]?.id;

                if ( pageId !== null && pageId !== undefined ) {
                    props._detailsPage(pageId, props.webApplicationLanguageCode);
                }
                else {
                    props._hideLoader();
                }
            }
        }, [ pageIndex ]
    )

    if ( pageIndex === props.pageListPayload?.length ) {

        return (
            <div className="site-main">
    
                <ContentHeaderRow 
                    title={languageVersion?.name} 
                    description={languageVersion?.value} 
                    resources={props.contentDetailPayload.resources}
                    resourceType={EResourceType.PARALLAX}
                />
    
                {
                    props.pageListPayload?.map((item:IClientPageList, index:number) => {
    
                        const itemLanguageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                            languageVersions: item.languageVersions,
                            filters: [
                                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                            ],
                            type: "find"
                        });
    
                        return (
                            <section key={item.id} className={`ttm-row element-style ${index%2===0?"":"ttm-bgcolor-grey"} clearfix`}>
                                <div className="container-xl">
                                    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="section-title text-center with-desc clearfix">
                                                <div className="title-header">
                                                    <h2 className="title text-center">{itemLanguageVersion?.name}</h2>
                                                    <h5 className="text-center ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(itemLanguageVersion?.value)}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ContentRenderer languageVersions={item.languageVersions} />
    
                                </div>
                            </section>
                        )
                    })
                }
    
            </div>
        )

    }

    return null;
    
}

const Component = React.memo(SHWRM004, (prevProps: TSHWRMLayout<PropsFromRedux>, nextProps: TSHWRMLayout<PropsFromRedux>) => {
    console.log("SHWRM004.memo", { prevProps, nextProps });
    return !(
        prevProps.pageListPayload !== nextProps.pageListPayload
        || prevProps.pageDetailsPayload !== nextProps.pageDetailsPayload
        || prevProps.isLoading !== nextProps.isLoading
    );
});

export default connector(Component);
