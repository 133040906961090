import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
import { isEmpty, reject } from 'lodash';
import { Outlet, useParams, useLocation } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
// components
import Footer from 'components/Footer';
import ScrollToTop from 'components/Gotop';
import Header from "components/Header";
import SEO from "components/SEO";
// actions
import SiteSettingsServiceAction from "cms/actions/common/SiteSettingsServiceAction";
//utilities
import PutManifestFileDynamic from "cms/utilities/PutManifestFileDynamic";
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientSiteSettingList from "cms/interfaces/IClientSiteSettingList";
import IClientLanguage from "core/interfaces/IClientLanguage";
// types
import TValidations from "cms/types/TValidations";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationLayout.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingListPayload:IClientSiteSettingList[]|null      = state.SiteSettingStore.list?.payload;
    const siteSettingListError:TValidations[]|null                  = state.SiteSettingStore.list?.error;
    const siteSettingListIsLoading:boolean                          = state.SiteSettingStore.list?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail|null    = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null                = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                        = state.SiteSettingStore.details?.isLoading;

    const error:TValidations[]|null                                 = reject( [siteSettingListError, siteSettingDetailError], isEmpty )[0] || [];
    const isLoading:boolean                                         = [siteSettingListIsLoading, siteSettingDetailIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingListPayload,
        siteSettingDetailPayload,

        error,
        isLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("WebApplicationLayout.mapDispatchToProps");

    const siteSettingsServiceAction = new SiteSettingsServiceAction(dispatch);

    return {
        // list
        _listSiteSettings: ( webApplicationLanguageCode:string ) => {
            siteSettingsServiceAction.list({ servicePayload: { language: webApplicationLanguageCode } });
        },

        // details
        _detailsSiteSettings: ( id:string, webApplicationLanguageCode:string ) => {
            siteSettingsServiceAction.details({ servicePayload: { key: id, language: webApplicationLanguageCode } });
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TContent<T> = {

} & T;


/* component */
function ContentLoader () {

    return (
        <></>
    )

}


function WebApplicationLayout ( props:TContent<PropsFromRedux> ) {
    console.log("WebApplicationLayout.rendered: ", props);

    const location = useLocation();
    const { category, categoryId, content, contentName, contentId } = useParams();

    useEffect(
        () => {
            if ( !isEmpty(props.webApplicationLanguageCode)) {
                props._listSiteSettings(props.webApplicationLanguageCode);
            }
        }, [ props.webApplicationLanguageCode ]
    )

    useEffect (
        () => {
            if ( !isEmpty(props.siteSettingListPayload) && props.siteSettingListPayload ) {
                props._detailsSiteSettings(props.siteSettingListPayload?.[0]?.id, props.webApplicationLanguageCode)
            }
        }, [ props.siteSettingListPayload ]
    )

    if ( !isEmpty(props.siteSettingListPayload) && !isEmpty(props.siteSettingDetailPayload) ) {

        PutManifestFileDynamic(document, props.siteSettingDetailPayload);

        return (
            <>
                <SEO siteSettingPayload={props.siteSettingDetailPayload} type="header" />
                
                <ScrollToTop />
    
                <Header />
                
                <Outlet 
                    context={{
                        category,
                        categoryId,
    
                        content,
                        contentName,
                        contentId,
                        
                        location
                    }}
                />
    
                <Footer mapLayoutName="MNPGGRP_002" />
            </>
        )

    }
    
    return null;

};

const Component = React.memo(WebApplicationLayout, ( prevProps:TContent<PropsFromRedux>, nextProps:TContent<PropsFromRedux> ) => {
    console.log("WebApplicationLayout.memo", { prevProps, nextProps });
    return !(
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.webApplicationLanguageCode !== nextProps.webApplicationLanguageCode
    )
});

export default connector(Component);
