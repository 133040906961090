// action
import OdataCoreActionBase from "core/actions/OdataCoreActionBase";
// service
import LanguageService from "core/services/language/LanguageService";


class LanguageServiceAction extends OdataCoreActionBase {

    constructor( dispatch: any, screenCode:string ) {

        super(dispatch, LanguageService, screenCode);
    
    }

}

export default LanguageServiceAction;
