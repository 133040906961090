import React from "react";
// interfaces
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EContentType } from "cms/enums/EContentType";
import { EViewType } from "cms/enums/EViewType";
import { EView } from "cms/enums/EView";


type TNoData = {

    icon: React.ReactNode;
    className?: string;
    textClassName?: string;
    languageVersions?: ILanguageVersionList[]|ILanguageVersionDetail[]; // siteSettings
    
}

function NoData ( props:TNoData ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.languageVersions,
        filters: [ 
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "noDataString" }
        ],
        type: "find"
    });

    return (
        <div className={props.className}>
            { props.icon }
            <p className={props.textClassName}>{ languageVersion?.value }</p>
        </div>
    );

}

export default NoData;
export type {
    TNoData
};