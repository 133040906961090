import React, { useState } from "react";
// components
import MobileHeader from "./components/header";
import MobileMenu from "./components/menu";
// types
import TMobileMenu from "./types/TMobileMenu";
// styles
import "./style.scss"


function MobileMenuComponent(props: TMobileMenu) {

    const [toggleCollapse, setToggleCollapse] = useState(false);

    const handleSidebarToggle = () => {
        console.log("This is toggle");
        setToggleCollapse(!toggleCollapse);
    };

    return (
        <>
            <MobileHeader
                handleSidebarToggle={handleSidebarToggle}
            />

            {
                toggleCollapse &&
                    <div className="tw-absolute tw-w-full tw-top-0 tw-mt-20 tw-flex tw-flex-col tw-justify-between tw-bg-white">
                        <MobileMenu
                            navigation={props.navigation}
                            toggleCollapse={!toggleCollapse}
                            handleSidebarToggle={handleSidebarToggle}
                        />
                    </div>
            }
            

        </>
    )

}

MobileMenuComponent.defaultProps = {
    navigation: [],
};

const Component = React.memo(MobileMenuComponent, (prevProps, nextProps) => {
    console.log("MobileMenuComponent.React.memo", {prevProps, nextProps});

    if ( prevProps.navigation !== nextProps.navigation ) {
        console.log("MobileMenuComponent.doRender");
        return false;
    }

    console.log("MobileMenuComponent.doNotRender");
    return true;
});

export default Component;

