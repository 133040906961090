// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientMenuItemService from "cms/services/ClientMenuItemService";


class ClientMenuItemServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientMenuItemService, screenCode);
    }

}

export default ClientMenuItemServiceAction;
