import React from "react";
// components
import RMDRLink from "components/RMDRLink";
// types
import { TFooterComponent } from "../type";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import { EResourceType } from "cms/enums/EResourceType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


function BottomBar ( props:TFooterComponent ) {
    console.log("BottomBar.rendered", props)

    const childsPayload = props.mainPageItemsPayload?.filter((item) => item.parentId === props.payload.id);

    const siteSettings = childsPayload?.find((item) => item.contentType === EContentType.SITE_SETTING);
    const pagesPayload = childsPayload?.filter((item) => item.contentType === EContentType.PAGE);

    const copyright = LanguageVersionSelector<ILanguageVersionList|undefined>({
        languageVersions: siteSettings?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "copyright" }
        ],
        type: "find"
    });

    return (
        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
            <div className="container-xl">
                <div className="tw-flex tw-justify-between copyright align-items-center res-767-text-center">
                    <div className="tw-flex-grow">
                        <span className="tw-text-sm" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(copyright?.value)}} />
                    </div>
                    <div>
                        <ul id="menu-footer-menu" className="footer-nav-menu">
                            {
                                pagesPayload?.map((item) => {

                                    const languageVersion =  LanguageVersionSelector<ILanguageVersionList|undefined>({
                                        languageVersions: item.languageVersions,
                                        filters: [
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                        ],
                                        type: "find"
                                    });

                                    const redirectUrl =  LanguageVersionSelector<ILanguageVersionList|undefined>({
                                        languageVersions: item.languageVersions,
                                        filters: [
                                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                        ],
                                        type: "find"
                                    });
                                    
                                    return (
                                        <li key={item.id}>
                                            <RMDRLink
                                                title={languageVersion?.name}
                                                text={languageVersion?.name}

                                                hasNavigationDestination={item.hasDestinationNavigation}

                                                navigationURL={languageVersion?.contentRouteNavigationURL}

                                                redirectType={item.redirectType}
                                                redirectUrl={redirectUrl?.value}
                                            />
                                        </li>
                                    )

                                })
                            }
                            {/* <li><a>About Us</a></li>
                            <li><a>Services</a></li>
                            <li><a>Privacy</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default BottomBar;
