// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientPagesService from "cms/services/ClientPagesService";
// enum
import EActionStatus from "core/enums/EActionStatus";
import EActionType from "core/enums/EActionType";
// interface
import { IActionGetOdata } from "core/interfaces/IActionGet";
import IActionRunParams from "core/interfaces/IActionRunParams";


class ClientPagesServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientPagesService, screenCode);
    }

    async search ( params: IActionGetOdata ) {

        this.fetching(EActionType.LIST);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.LIST
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.search.bind(this.service), serviceParams );
        
    }

}

export default ClientPagesServiceAction;
