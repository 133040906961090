import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import RMDRImage from "components/RMDRImage";
// actions
import CoreAction from "core/actions/common/CoreAction";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


function mapStateToProps ( state: any ) {
    console.log("Error404.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        siteSettingDetailPayload

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);

    return {

        _hideLoader: () => { 
            coreAction.hideLoader() 
        }
        
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TError404<T> = {

} & T;


/* component */
function Error404Loader () {

    return (
        <></>
    )

}

function Error404 ( props: TError404<PropsFromRedux> ) {

    const pageHeader = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"404PageHeader"
            }
        ],
        type:"find"
    });

    const pageBody = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"404PageBody"
            }
        ],
        type:"find"
    });

    const backToHomeString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            {
                view: EView.META_DATA, viewType:EViewType.TEXT, contentType:EContentType.DEFAULT, name:"backToHomeString"
            }
        ],
        type:"find"
    });

    useEffect(
        () => {
            props._hideLoader();
        }, []
    )

    return (
        <div className="site-main">
            <section className="error-404">
                
                    <div className="row">
                        <div className="col-md-12">

                            <div style={{"textAlign": "center"}}>
                                <RMDRImage
                                    src="images/oops.png"
                                    alt="Error Boundary"
                                    title="Error Boundary"
                                />
                            </div>

                            <header className="page-header">
                                <h1 className="page-title">{pageHeader?.value}</h1>
                            </header>
                            
                            <div className="page-content">
                                <div className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(pageBody?.value)}} />
                            </div>
                            
                            <a
                                className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mb-15"
                                href={process.env.PUBLIC_URL + "/"}
                            >
                                {backToHomeString?.value}
                            </a>

                        </div>
                    </div>

            </section>
        </div>
    );

};

const Component = React.memo(Error404, ( prevProps:TError404<PropsFromRedux>, nextProps:TError404<PropsFromRedux> ) => {
    console.log("Error404.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
