import { combineReducers } from 'redux';
// reducers
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// interfaces
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';
// enums
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';
import EActionType from 'core/enums/EActionType';

const reduxStorages = new CoreReduxStore( { screenCode: "COM", serviceCode: "Core" } as ICoreReduxStore )

/**
 * - Uygulama ana verileri burada depolanır
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode COM(Common) ve serviceCode Core(Core) olarak set edilmiştir.
 */

const CoreStore = combineReducers({

    language: reduxStorages.BASE({ 
        storeKey: "armador",
        protocolKey: EActionType.LANGUAGE,
        initialState: EReduxStoreInitialState.OBJECT
    })["armador"],
    languageCode: reduxStorages.BASE({ 
        storeKey: "armador",
        protocolKey: EActionType.LANGUAGE_CODE,
        initialState: EReduxStoreInitialState.DEFAULT
    })["armador"],

    loader: reduxStorages.BASE({ 
        storeKey: "armador",
        protocolKey: EActionType.LOADER,
        initialState: EReduxStoreInitialState.DEFAULT
    })["armador"],

    colorPalette: reduxStorages.BASE({ 
        storeKey: "armador",
        protocolKey: EActionType.COLOR_PALETTE,
        initialState: EReduxStoreInitialState.OBJECT
    })["armador"],
    colorPaletteMode: reduxStorages.BASE({ 
        storeKey: "armador",
        protocolKey: EActionType.COLOR_PALETTE_MODE,
        initialState: EReduxStoreInitialState.OBJECT
    })["armador"]

});

export default CoreStore;
