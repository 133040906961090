import React from "react";
import SocialMedia02 from "components/SocialMedia/components/SocialMedia02";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";


function TopBar ( props:any ) {
        
    const address = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "address" }], type:"find"});
    const phoneNumber = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "phoneNumber" }], type:"find"});
    const workingHours = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "workingHours" }], type:"find"});

    return (
        <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
            <div className="container-xl">
                <div className="row">
                    <div className="col-lg-7">
                        <ul className="top-contact text-left">
                            <li><i className="fas fa-map-marker-alt pr-2" />{ address?.value }</li>
                            <li><i className="fa fa-phone pr-2" />{ phoneNumber?.value }</li>
                        </ul>
                    </div>
                    <div className="col-lg-5">
                        <div className="topbar-right text-right d-flex justify-content-end">
                            <ul className="top-contact">
                                <li><i className="far fa-clock pr-2" />{ workingHours?.value }</li>
                            </ul>
                            
                            <SocialMedia02
                                payload={props.siteSettingPayload?.languageVersions||[]}
                                tooltip="bottom"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

const Component = React.memo(TopBar, (prevProps:any, nextProps:any) => {
    console.log("TopBar.memo", { prevProps, nextProps });
    return !( 
        prevProps.siteSettingPayload !== nextProps.siteSettingPayload
    )
});

export default Component;
