import React from "react";
import ReactDOM from "react-dom/client";

// css
import "./index.scss";
// analytics
import "core/extensions/AnalyticsExtension";
// screens
import App from "./app";

//
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);


root.render(
    <App />
);
