import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";
// extensions
import FetchExtension from 'core/extensions/FetchExtension';
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IOdataGetRequest } from 'core/interfaces/IGetRequest';
// enum
import HttpMethodEnum from 'core/enums/EHttpMethod';


class ClientPagesService extends ReadOnlyOdataServiceBase {

    static code = "204";
    code = "204";

    constructor () {

        super({
            sourcePath: "client-pages",
        });

    }

    async search( params: IOdataGetRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.GET,
            language: params.language,

			sourcePath: `${this.sourcePath}/search`,
            queryOptions: params.queryOptions
		}

		return FetchExtension(apiFetchConfig);
	
    }

}

export default ClientPagesService;
