import React, { useRef, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import CoreAction from "core/actions/common/CoreAction";
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
import ContactUsServiceAction from "cms/actions/components/InnerLayouts/INNR_003/ContactUsServiceAction";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
import RMDRTextBox from "components/RMDRTextBox";
import RMDRTextArea from "components/RMDRTextArea";
import RMDRButton from "components/RMDRButton";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IBase from "cms/interfaces/IBase";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
// type
import { TINNRLayout } from "../type";
import TValidations from "cms/types/TValidations";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";


function mapStateToProps ( state: any ) {
    console.log("INNR003.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const contactUsPostPayload:IBase                = state.INNR003Store.ContactUsStore.post?.payload;
    const contactUsPostError:TValidations[]|null    = state.INNR003Store.ContactUsStore.post?.error?.value;
    const contactUsPostIsLoading:boolean            = state.INNR003Store.ContactUsStore.post?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        contactUsPostPayload,
        contactUsPostError,
        contactUsPostIsLoading,
        
        siteSettingDetailPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("INNR003.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const contactUsServiceAction = new ContactUsServiceAction(dispatch);
    const messagePublisherAction = new MessagePublisherAction(dispatch);

    return {
        // list
        // post
        _post: ( payload:any, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: payload, language: webApplicationLanguageCode } })
        },
        
        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message);
        },

        // details
        // others
        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        },

        _clean: () => {
            contactUsServiceAction.postClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR003 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR003.rendered: ", props);
    
    const [ verboseResources, setVerboseResources ] = useState<any>({});
    const [disabled, setDisabled] = useState(false);

    const contactUsNameSurname = useRef<any>(null);
    const contactUsEmail = useRef<any>(null);
    const contactUsMessage = useRef<any>(null);
    const contactUsTitle = useRef<any>(null);

    useEffect(
        () => {
            // string Values
            const comeVisitUsAtString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "comeVisitUsAtString" }], type: 'find' });
            const ourAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourAddressString" }], type: 'find' });
            const officeAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "officeAddressString" }], type: 'find' });
            const ourPhoneNumberString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourPhoneNumberString" }], type: 'find' });
            const ourEmailString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourEmailString" }], type: 'find' });
            const dropUsALineString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "dropUsALineString" }], type: 'find' });
            const fullNameString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "fullNameString" }], type: 'find' });
            const emailAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "emailAddressString" }], type: 'find' });
            const writeAMessageString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "writeAMessageString" }], type: 'find' });
            const subjectString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "subjectString" }], type: 'find' });
            const submitString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "submitString" }], type: 'find' });

            // values
            const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }, { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE }, { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY }], type: 'find' });
            const googleMaps = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "googleMaps" }], type: 'find' });
            const phoneNumber = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "phoneNumber" }], type: 'find' });
            const address = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "address" }], type: 'find' });
            const email = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }], type: 'find' });

            const sendMessageString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "sendMessageString" }], type: 'find' });
            const messageSendingString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageSendingString" }], type: 'find' });
            const messageHasBeenSentString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageHasBeenSentString" }], type: 'find' });

            setVerboseResources({
                comeVisitUsAtString, ourAddressString, officeAddressString, ourPhoneNumberString, ourEmailString, dropUsALineString, fullNameString, emailAddressString, writeAMessageString, subjectString, submitString,
                languageVersion, googleMaps, phoneNumber, address, email,
                sendMessageString, messageSendingString, messageHasBeenSentString
            });
            
            setTimeout(() => { props._hideLoader(); }, 100);

            return function cleanup() {

                props._clean();
                props._showLoader();

            }

        }, []
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostPayload?.id) ) {
                contactUsNameSurname.current.value = "";
                contactUsEmail.current.value = "";
                contactUsMessage.current.value = "";
                contactUsTitle.current.value = "";

                props._clean();
                setDisabled(false);
                props._showMessage(verboseResources.messageHasBeenSentString?.value||"");
                
            }
        }, [ props.contactUsPostPayload ]
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostError) ) {
                setDisabled(false);
            }
        }, [ props.contactUsPostPayload ]
    )
    
    function onSubmit(event:React.SyntheticEvent) { 
        event.preventDefault();

        setDisabled(true);

        const target = event.target as typeof event.target & {
            nameSurname: { value: string },
            title: { value: string },
            email: { value: string },
            message: { value: string },
        };

        const payload:any = {
            nameSurname: target.nameSurname.value,
            email: target.email.value,
            message: target.message.value,
            title: target.title.value,
        }
        props._post(payload, props.webApplicationLanguageCode);
    }

    return (
        <>
            <ContentHeaderRow 
                title={verboseResources.languageVersion?.name} 
                description={verboseResources.languageVersion?.value} 
                resources={props.contentDetailPayload.resources} 
                resourceType={EResourceType.PARALLAX}
            />

            <section className="ttm-row zero-padding-section clearfix">
                <div className="container-xl">
                    <div className="row no-gutters">

                        <div className="col-lg-5">
                            <div className="spacing-9">
                                <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h5>{ verboseResources.comeVisitUsAtString?.value }</h5>
                                        <h2 className="title">{ verboseResources.ourAddressString?.value }</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="ti-location-pin" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.officeAddressString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>{ verboseResources.address?.value }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.ourPhoneNumberString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p><a className="tw-no-underline" href={`tel:${verboseResources.phoneNumber?.value}`}>{ verboseResources.phoneNumber?.value }</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="ti ti-email" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.ourEmailString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p><a className="tw-no-underline" href={`mailto: ${verboseResources.email?.value}`}>{ verboseResources.email?.value }</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <form className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span" onSubmit={onSubmit} >
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                    <div className="ttm-bg-layer-inner" />
                                </div>
                                <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h5>{ verboseResources.sendMessageString?.value }</h5>
                                        <h2 className="title">{ verboseResources.dropUsALineString?.value }</h2>
                                    </div>
                                </div>
                                
                                <div id="ttm-quote-form" className="row ttm-quote-form clearfix">
                                    <div className="col-sm-12 col-md-12">
                                        <div className="input-group mb-3 tw-flex-col">
                                            <RMDRTextBox rmdrRef={contactUsNameSurname} name="nameSurname" className="form-control bg-white !tw-w-full" placeholder={verboseResources.fullNameString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="input-group mb-3 tw-flex-col">
                                            <RMDRTextBox rmdrRef={contactUsEmail} name="email" className="form-control bg-white !tw-w-full" placeholder={verboseResources.emailAddressString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="input-group mb-3 tw-flex-col">
                                            <RMDRTextBox rmdrRef={contactUsTitle} name="title" className="form-control bg-white !tw-w-full" placeholder={verboseResources.subjectString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="input-group mb-3 tw-flex-col">
                                            <RMDRTextArea rmdrRef={contactUsMessage} name="message" className="form-control bg-white !tw-w-full" placeholder={verboseResources.writeAMessageString?.value} disabled={disabled} rows={5} errors={props.contactUsPostError} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="text-left">
                                            <RMDRButton
                                                type="submit"
                                                value={verboseResources.sendMessageString?.value}
                                                disabledValue={verboseResources.messageSendingString?.value}
                                                disabled={disabled}
                                                className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </section>

            {
                verboseResources.googleMaps?.value &&
                    <section className="ttm-row map-section res-991-p-0 clearfix">
                        <div className="map-wrapper">
                            <iframe name="googleMaps" title="googleMaps" height="100%" src={verboseResources.googleMaps?.value} />
                        </div>
                    </section>
            }
            
        </>
    )
}

const Component = React.memo(INNR003, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR003.memo", { prevProps, nextProps });
    return !( prevProps.contactUsPostIsLoading !== nextProps.contactUsPostIsLoading );
});

export default connector(Component);
