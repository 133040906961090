import SLDR_001 from "./components/SLDR_001";
import SLDR_002 from "./components/SLDR_002";

const SliderLayouts:any = {
    
    SLDR_001,
    SLDR_002
}

export default SliderLayouts;
