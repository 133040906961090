const EContentTypeDataSource = {

    DEFAULT             : { id: 1,  name: "Default" },
    PAGE                : { id: 2,  name: "Page" },
    CATEGORY            : { id: 4,  name: "Category" },
    MENU_ITEM           : { id: 8,  name: "Menu Item" },
    SLIDER_ITEM         : { id: 16, name: "Slider Item" },
    MAIN_PAGE_ITEM      : { id: 32, name: "Main Page Item" },
    STATISTIC_ITEM      : { id: 64, name: "Statistic Item" },
    SITE_SETTING        : { id: 128,name: "Site Setting" },
    DATA                : { id: 256,name: "Data" },

    VENDOR              : { id: 2048,name: "Vendor" },
    STORE               : { id: 4096,name: "Store" },
    BRAND               : { id: 8192,name: "Brand" },
    CATALOG_CATEGORY    : { id: 16384, name: "Catalog Category" },
    PRODUCT             : { id: 32768, name: "Product" },
    PRODUCT_VARIANT     : { id: 65536, name: "Product Variant" }

}


enum EContentType {

    DEFAULT             = 1,
    PAGE                = 2,
    CATEGORY            = 4,
    MENU_ITEM           = 8,
    SLIDER_ITEM         = 16,
    MAIN_PAGE_ITEM      = 32,
    STATISTIC_ITEM      = 64,
    SITE_SETTING        = 128,
    DATA                = 256,

    VENDOR              = 2048,
    STORE               = 4096,
    BRAND               = 8192,
    CATALOG_CATEGORY    = 16384,
    PRODUCT             = 32768,
    PRODUCT_VARIANT     = 65536,

}

export default EContentTypeDataSource;
export {

    EContentType

}
