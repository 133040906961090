// actions
import ActionBase from "core/actions/ActionBase";
// enums
import EActionStatus from 'core/enums/EActionStatus';
import EActionType from 'core/enums/EActionType';
// interfaces
import IModifierPayload from 'core/interfaces/IModifierPayload';
// types
import TActionType from "core/types/TActionType";


class ActionCore extends ActionBase {

    fetching( actionType:TActionType, screenCode?:string, serviceCode?:string ) {

        this.dispatch({ 
            type: `${screenCode ?? this.screenCode}${serviceCode ?? this.serviceCode}${actionType}${EActionStatus.FETCHING}` 
        });

    }

    modify( actionType:TActionType, payload:any, screenCode?:string, serviceCode?:string ) {

        this.dispatch({ 
            type: `${screenCode ?? this.screenCode}${serviceCode ?? this.serviceCode}${actionType}${EActionStatus.MODIFIER}`,
            payload
        });

    }

    store( actionType:TActionType, payload:any, screenCode?:string, serviceCode?:string ) {

        this.dispatch({ 
            type: `${screenCode ?? this.screenCode}${serviceCode ?? this.serviceCode}${actionType}${EActionStatus.STORE}`,
            payload
        });

    }

    set( actionType:TActionType, payload:any, screenCode?:string, serviceCode?:string ) {
        
        this.dispatch({ 
            type: `${screenCode ?? this.screenCode}${serviceCode ?? this.serviceCode}${actionType}${EActionStatus.SUCCESS}`,
            payload
        });

    }

    clean( actionType:TActionType, key?:string, screenCode?:string, serviceCode?:string ) {

        this.dispatch({ 
            type: `${screenCode ?? this.screenCode}${serviceCode ?? this.serviceCode}${actionType}${EActionStatus.CLEAN}`,
            key
        });

    }

    storageModifier ( payload: IModifierPayload[], screenCode?:string, serviceCode?:string ) {

        this.modify(EActionType.STORAGE, payload, screenCode, serviceCode);

    }

    storageStore ( payload:{}, screenCode?:string, serviceCode?:string ) {

        this.store(EActionType.STORAGE, payload, screenCode, serviceCode);

    }

    storageClean() {

        this.clean(EActionType.STORAGE);
    
    }

}

export default ActionCore;
