import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ContactUsService from 'cms/services/ContactUsService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ContactUsServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ContactUsService.code });

    return combineReducers({

        ...reduxStorages.POST()
    
    });
}

export default ContactUsServiceStore;
