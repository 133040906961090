import React, { useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { EViewType } from "cms/enums/EViewType";
import { EView } from "cms/enums/EView";



function mapStateToProps ( state: any ) {
    console.log("ContentSearch.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    return {
        
        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload

    }

}

function mapDispatchToProps ( dispatch:Dispatch ) {
    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TContentSearch<T> = {

} & T;


/* component */
function ContentLoader () {

    return (
        <></>
    )

}

function ContentSearch ( props:TContentSearch<PropsFromRedux> ) {
    console.log("ContentSearch.rendered", props);

    const history = useNavigate();
    const searchRef = useRef<any>();
    const [showSearchOverlay, setShowSearchOverlay] = useState(false);


    const typeWordThenEnter = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "typeWordThenEnter" }], type: 'find' });


    function toggleSearchOverlay() {

        setShowSearchOverlay(!showSearchOverlay);
        setTimeout(() => {
            searchRef.current?.focus();
        },100)

    }

    function onsubmit( event:any ) {

        event.preventDefault();

        const target = event.target as typeof event.target & {
            search: { value: string }
        };

        if ( target.search.value && !isEmpty(target.search.value) ) {
            history({
                pathname: `/${props.webApplicationLanguageISOCode}/search`,
                search: `?q=${target.search.value}`, 
            });
        }

        setShowSearchOverlay(false);

    }

    return (
        <li className="ttm-header-search-link ttm-bgcolor-darkgrey ttm-textcolor-white tw-cursor-pointer">

            <div onClick={toggleSearchOverlay}> 
                { 
                    showSearchOverlay ? <i className="ti-close" /> : <i className="ti-search" />
                }
            </div>

            {
                showSearchOverlay && 
                    <div className="ttm-search-overlay">
                        <div className="ttm-site-searchform">
                            <div className="w-search-form-h">
                                <div className="w-search-form-row">
                                    <form className="w-search-input" onSubmit={onsubmit} noValidate>
                                        <input ref={searchRef} name="search" type="search" className="field searchform-s" placeholder={typeWordThenEnter?.value||""} />
                                        <button type="submit"><i className="ti ti-search" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </li>
    )
    
}

const Component = React.memo(ContentSearch, ( prevProps:TContentSearch<PropsFromRedux>, nextProps:TContentSearch<PropsFromRedux> ) => {
    console.log("ContentSearch.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
