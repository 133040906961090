// interface
import IServiceBase from "core/interfaces/IServiceBase";


class ServiceBase {

    sourcePath: string  = "";
    code: string        = "";

    constructor ( { sourcePath }: IServiceBase ) {
        this.sourcePath = sourcePath
    }

}

export default ServiceBase;
