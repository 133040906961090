function calculatePageNumbers ( currentPage:number, pageSize:number ) {
    
    const first_page_numbers = [];
    const middle_page_numbers = [];
    const last_page_numbers = [];

    if(currentPage-1 < 6) {
        // 1,2,3,4,5,6,7,8,9,10,...,100
        if (pageSize < 11) {
            for (let i=1;i<=pageSize;i++) middle_page_numbers.push(i);
        }
        else {
            for (let i=1;i<=10;i++) middle_page_numbers.push(i);
            last_page_numbers.push(pageSize);
        }
    }
    else if(pageSize-currentPage-1 < 6) {
        // 1,...,91,92,93,94,95,96,97,98,99,100
        first_page_numbers.push(1);
        for (let i=pageSize-9;i<=pageSize;i++) middle_page_numbers.push(i);
    }
    else {
        // 1...,11,12,13,14,15,16,17,18,19,...,100
        first_page_numbers.push(1);
        for (let i=currentPage-4;i<=currentPage+4;i++) middle_page_numbers.push(i);
        last_page_numbers.push(pageSize);
    }

    return [
        first_page_numbers,
        middle_page_numbers,
        last_page_numbers
    ]

}

export default calculatePageNumbers;
