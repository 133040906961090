import React from "react";
// types
import { TMainPageLayout } from "../type";
// components
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ItemBox from "components/ItemBox";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import SocialMedia01 from "components/SocialMedia/components/SocialMedia01";


function mapStateToProps ( state: any ) {
    console.log("MNPG013.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG013 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG013.rendered", props)

    // const address = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "address" }], type: "find"});
    // const phoneNumber = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "phoneNumber" }], type: "find"});
    // const email = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }], type: "find"});

    // const talkToOurSupportString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "talkToOurSupportString" }], type: "find"});
    // const writeToOurSupportString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "writeToOurSupportString" }], type: "find"});
    // const supportAddressString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.parentPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "supportAddressString" }], type: "find"});

    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
            {/* <div className="widget widget_text clearfix">

                <ItemBox
                    title={talkToOurSupportString?.value||""}
                    value={phoneNumber?.value||""}
                    icon="flaticon flaticon-clock"
                    className="mt-0"
                />

                <ItemBox
                    title={writeToOurSupportString?.value||""}
                    value={email?.value||""}
                    icon="ti ti-email"
                />

                <ItemBox
                    title={supportAddressString?.value||""}
                    value={address?.value||""}
                    icon="ti ti-location-pin"
                />

                <div className="quicklink-box">
                    <SocialMedia01
                        payload={props.parentPayload?.languageVersions}
                        tooltip="top"
                    />
                </div>

            </div> */}
        </div>
    );

}

const Component = React.memo(MNPG013, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG013.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
