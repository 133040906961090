// action
import ActionCore from "./ActionCore";
// enum
import EActionType from "core/enums/EActionType";
// interface
import { IActionGetOdata } from "core/interfaces/IActionGet";
import IActionRunParams from "core/interfaces/IActionRunParams";


class OdataCoreActionBase extends ActionCore {

    async list ( params: IActionGetOdata ) {

        this.fetching(EActionType.LIST);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.LIST
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.get.bind(this.service), serviceParams );
        
    }

    async listModifier ( params: IActionGetOdata ) {

        this.fetching(EActionType.LIST);
        this.modify(EActionType.LIST, params.payload);
        
    }

    async listStore ( params: IActionGetOdata ) {

        this.store(EActionType.LIST, params.payload);
        
    }

    listClean () {

        this.clean(EActionType.LIST);

    }

    async get ( params:IActionGetOdata ) {

        this.fetching(EActionType.GET);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.GET
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.get.bind(this.service), serviceParams );

    }

    async getClean( key:string ) {

        this.clean(EActionType.GET, key);
    
    }

}

export default OdataCoreActionBase;
