import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientSliderItemService from 'cms/services/ClientSliderItemService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientSliderItemServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientSliderItemService.code });

    return combineReducers({

        ...reduxStorages.LIST()
    
    });
}

export default ClientSliderItemServiceStore;
