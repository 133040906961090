import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';

const reduxStorages = new CoreReduxStore( { screenCode: "COM", serviceCode: "SS" } as ICoreReduxStore )

/**
 * - Site Settings api sinden alınan veriler burada depolanır.
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode COM(Common) ve serviceCode SS(SiteSettings) olarak set edilmiştir.
 */

const SiteSettingStore = combineReducers({

    ...reduxStorages.LIST(),
    ...reduxStorages.DETAILS(),

});

export default SiteSettingStore;