import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientMenuItemService extends ReadOnlyOdataServiceBase {

    static code = "203";
    code = "203";

    constructor () {
        super({
            sourcePath: "client-menu-items"
        });
    }

}

export default ClientMenuItemService;
