import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientMenuItemService from 'cms/services/ClientMenuItemService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientMenuItemServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientMenuItemService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.GET()
    
    });
}

export default ClientMenuItemServiceStore;
