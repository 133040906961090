import { combineReducers } from 'redux';
// 
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";



const screenCode = "205";

const LatestContentsStore = combineReducers({
 
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),
    
});

export default LatestContentsStore;
