import TSocialMedia from "../type";
import {
    IoLogoInstagram,
    IoLogoFacebook,
    IoLogoYoutube,
    IoLogoLinkedin,
} from 'react-icons/io5';
import {
    FaXTwitter
} from 'react-icons/fa6'


function SocialMedia02 ( props:TSocialMedia ) {

    const facebook = props?.payload?.find((item) => item.name === "facebook");
    const x = props?.payload?.find((item) => item.name === "x");
    const instagram = props?.payload?.find((item) => item.name === "instagram");
    const linkedin = props?.payload?.find((item) => item.name === "linkedin");
    const youtube = props?.payload?.find((item) => item.name === "youtube");

    return (
        <div className="ttm-social-links-wrapper list-inline">
            <ul className="social-icons">
                { facebook && <li><a className={`${props.tooltip?`tooltip-${props.tooltip}`:""}`} title="Facebook" data-tooltip="Facebook" aria-label="Facebook" href={facebook.value||""}><IoLogoFacebook /></a></li> }
                { x && <li><a className={`${props.tooltip?`tooltip-${props.tooltip}`:""}`} title="X" data-tooltip="X" aria-label="X" href={x.value||""}><FaXTwitter /></a></li> }
                { instagram && <li><a className={`${props.tooltip?`tooltip-${props.tooltip}`:""}`} title="Instagram" data-tooltip="Instagram" aria-label="Instagram" href={instagram.value||""}><IoLogoInstagram /></a></li> }
                { linkedin && <li><a className={`${props.tooltip?`tooltip-${props.tooltip}`:""}`} title="LinkedIn" data-tooltip="LinkedIn" aria-label="LinkedIn" href={linkedin.value||""}><IoLogoLinkedin /></a></li> }
                { youtube && <li><a className={`${props.tooltip?`tooltip-${props.tooltip}`:""}`} title="Youtube" data-tooltip="Youtube" aria-label="Youtube" href={youtube.value||""}><IoLogoYoutube /></a></li> }
            </ul>
        </div>            
    );
    
}

export default SocialMedia02;
