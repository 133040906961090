import EActionType from "core/enums/EActionType";
import EMessageStatus from "core/enums/EMessageStatus";


class MessagePublisherAction {

    dispatch:any;

    constructor ( dispatch:any ) {
        this.dispatch = dispatch;
    }

    /**
     * show success message
     */
    showSuccessMessage( message:string ) {
        this.dispatch({
            type: `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.SUCCESS}`,
            payload: message
        });
    }

    /**
     * show error message
     */
    showErrorMessage( message:string ) {
        this.dispatch({
            type: `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.ERROR}`,
            payload: message
        });
    }

    /**
     * show warning message
     */
    showWarningMessage( message:string ) {
        this.dispatch({
            type: `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.WARNING}`,
            payload: message
        });
    }

    /**
     * show info message
     */
    showInfoMessage( message:string ) {
        this.dispatch({
            type: `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.INFO}`,
            payload: message
        });
    }

}

export default MessagePublisherAction;
