import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
import { isEmpty } from 'lodash';
import { Outlet } from "react-router-dom";
// actions
import LanguageServiceAction from "cms/actions/components/WebApplicationStarter/LanguageServiceAction";
import CoreAction from "core/actions/common/CoreAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientLangauge from "core/interfaces/IClientLanguage";
// utilities
import DetectLanguage from "cms/utilities/DetectLanguage";
// types
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationStarter.mapStateToProps: ", state);

    const languageStorePayload:IClientLangauge[]|null   = state.LanguageStore.list?.payload;
    const languageStoreError:TValidations[]|null        = state.LanguageStore.list?.error;
    const languageStoreIsLoading:boolean                = state.LanguageStore.list?.isLoading;

    const detectLanguage                = DetectLanguage(languageStorePayload);
    const webApplicationLanguage        = detectLanguage.webApplicationLanguage;
    const webApplicationLanguageISOCode = detectLanguage.webApplicationLanguageISOCode
    const webApplicationLanguageCode    = detectLanguage.webApplicationLanguageCode

    return {

        languageStorePayload,
        languageStoreError,
        languageStoreIsLoading,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const languageServiceAction = new LanguageServiceAction(dispatch);

    return {
        _listLanguages: () => {
            let queryOptions:IOdataQueryOptions = {
                filter: ["isActive eq true"],
                top: 1000
            };

            languageServiceAction.list({ servicePayload: { queryOptions }, args:{ "keysToBeRemoved": ["id"] } });
        },

        _setWebApplicationLanguage: ( language:any ) => {
            coreAction.setWebApplicationLanguage(language);
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TWebApplicationStarter<T> = {

} & T;


/* component */
const WebApplicationStarter = ( props:TWebApplicationStarter<PropsFromRedux> ) => {
    console.log("WebApplicationStarter.rendered: ", props);

    useEffect(
        () => {
            props._listLanguages();
        }, []
    )

    if ( !isEmpty(props.webApplicationLanguageISOCode) && props.webApplicationLanguageISOCode && !props.languageStoreIsLoading ) {
        props._setWebApplicationLanguage(props.webApplicationLanguage);
        document.documentElement.lang = props.webApplicationLanguageISOCode;
        return <Outlet />;
    }

    return null;

};

const Component = React.memo(WebApplicationStarter, ( prevProps:TWebApplicationStarter<PropsFromRedux>, nextProps:TWebApplicationStarter<PropsFromRedux> ) => {
    console.log("WebApplicationStarter.memo", { prevProps, nextProps });
    return !( 
        prevProps.languageStoreIsLoading !== nextProps.languageStoreIsLoading 
        || prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode    
    );
});

export default connector(Component);
