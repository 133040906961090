// enum
import HttpStatusCode from "core/enums/EHttpStatusCode";
import EActionType from "core/enums/EActionType";
import EActionStatus from "core/enums/EActionStatus";
// interface
import IServiceResponse from "core/interfaces/IServiceResponse";
import IActionCallbackArgs from "core/interfaces/IActionCallbackArgs";
import IActionRunParams from "core/interfaces/IActionRunParams";
import deleteKeyFromListOfDictionary from "core/utilities/DeleteKeyFromListOfDictionary";
import TDispatchPayload from "core/types/TDispatchPayload";


export default class ActionBase {

    dispatch: any = null;
    service: any = null;

    serviceCode: string = "";
    screenCode: string = "";

    constructor ( dispatch: any, service: any, screenCode: string ) {

        this.dispatch = dispatch;
        if ( service ) {
            this.service = new service();
            this.serviceCode = service.code;
        }
        if (screenCode) this.screenCode = screenCode;
    
    }

    callback200( response: IServiceResponse, args?: IActionCallbackArgs ) {

        if ( args?.actionType === EActionType.LIST || args?.actionType === EActionType.POST ) {
            this.dispatch({ 
                type: `${this.screenCode}${this.serviceCode}${args.actionType}${EActionStatus.SUCCESS}`, 
                payload: args["keysToBeRemoved"] ? deleteKeyFromListOfDictionary(response.value, args["keysToBeRemoved"] as string[]) : response.value,
                count: response?.["@odata.count"]
            });
        } 
        else if ( args?.actionType === EActionType.DETAILS ) {
            this.dispatch({ 
                type: `${this.screenCode}${this.serviceCode}${args.actionType}${EActionStatus.SUCCESS}`, 
                payload: response.value
            });
        }
        else if ( args?.actionType === EActionType.GET ) {

            // extraPayload parametresinin çalışbilmesi için sayfadan yapılan istek aşağıdaki gibi olmalıdır. İstek içindeki storeMapOptions değişkeni api den gelen verinin içindeki bilginin nereye atanacağını gösteriyor.
            // dataMetricsAction.list({ servicePayload: queryOptions, args: { storeMapOptions: [{ destinationName: "totalCountGroupByMetricType", sourceName: "value" }]  } }); 

            if ( args.storeMapOptions && Array.isArray(args.storeMapOptions) ) {
                let dispatchPayload:TDispatchPayload = {
                    type: `${this.screenCode}${this.serviceCode}${args.actionType}${EActionStatus.SUCCESS}`,
                    extraPayload: {}
                }

                for ( let item of args.storeMapOptions ) {
                    dispatchPayload.extraPayload[item.destinationName] = (response as any)?.[item.sourceName];
                }

                this.dispatch(dispatchPayload);
            }
            
        }

    }

    callback201( response: IServiceResponse, args?: IActionCallbackArgs ) {

    }

    callback400( response: IServiceResponse, args?: IActionCallbackArgs ) {

        if ( args?.actionType === EActionType.LIST || args?.actionType === EActionType.POST || args?.actionType === EActionType.DETAILS ) {
            this.dispatch({ 
                type: `${this.screenCode}${this.serviceCode}${args.actionType}${EActionStatus.FAIL}`, 
                payload: response
            });
        }

    }

    callback401( response: IServiceResponse, args?: IActionCallbackArgs ) {
    
    }

    callback406( response: IServiceResponse, args?: IActionCallbackArgs ) {

        this.callback400( response, args );

    }

    callback500( response?: IServiceResponse, args?: IActionCallbackArgs ) {

    }

    callbackOther( response?: IServiceResponse, args?: IActionCallbackArgs ) {

    }
    
    callbackError( response?: IServiceResponse, args?: IActionCallbackArgs ) {

    }

    executeService( service: any, params: IActionRunParams ) {
        
        service( params.servicePayload )
            .then( (response: any) => {
                switch (response.status) {

                        // from data
                    case HttpStatusCode.OK:
                        this.callback200(response.data, params.args);
                        break;

                    case HttpStatusCode.CREATED:
                        this.callback201(response.data, params.args);
                        break;

                    case HttpStatusCode.ACCEPTED:
                        this.callback201(response.data, params.args);
                        break;

                    case HttpStatusCode.BAD_REQUEST:
                        this.callback400(response.data, params.args);
                        break;

                    case HttpStatusCode.UNAUTHORIZED:
                        this.callback401(response.data, params.args);
                        break;

                    case HttpStatusCode.NOT_ACCEPTABLE:
                        this.callback406(response.data, params.args);
                        break;

                    case HttpStatusCode.INTERNAL_SERVER_ERROR:
                        this.callback500(response, params.args);
                        break;

                    default:
                        this.callbackOther(response, params.args);

                }

                return Promise.resolve();

            })
            .catch( (error: any) => {

                console.error("executeService.error: ", JSON.stringify(error, null, 4));

                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.error("executeService.catch: ", message);

                this.callbackError(message, params?.args);

                return Promise.resolve();

            });
    }
}
