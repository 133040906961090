import Axios, { AxiosRequestConfig } from 'axios';
import Cookies from "universal-cookie";
// core
import CoreDefaults from 'core/Defaults';
// interfaces
import IFetchExtension from 'core/interfaces/IFetchExtension';
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import sleep from 'core/utilities/sleep';

// constants
const cookies = new Cookies();
declare module "axios" {
  export interface AxiosRequestConfig {
    responseEncoding?: string;
  }
}


function OdataQueryComposer( baseUrl: string, queryOptions?: (IOdataQueryOptions|null) ) : string {
    if ( queryOptions === null ) return baseUrl;

    const queryList = [];
    let url = baseUrl;

    
    if (queryOptions?.filter && queryOptions?.filter?.length > 0) {
        queryList.push(`$filter=${queryOptions.filter.join(' ')}`);
    }
    if (queryOptions?.skip  && queryOptions?.skip >= 0) {
        queryList.push(`$skip=${queryOptions.skip}`);
    }
    if (queryOptions?.top && queryOptions?.top >= 0) {
        queryList.push(`$top=${queryOptions.top}`);
    }
    if (queryOptions?.orderBy && queryOptions?.orderBy?.length > 0) {
        queryList.push(`$orderby=${queryOptions.orderBy.join(',')}`);
    }
    if (queryOptions?.expands && queryOptions?.expands?.length > 0) {
        queryList.push(`$expand=${queryOptions.expands.join(',')}`);
    }
    if (queryOptions?.count === true) {
        queryList.push(`$count=true`);
    }

    if (queryList.length > 0) {
        url = `${baseUrl}?${queryList.join('&')}`;
    }

    return url;
}

async function FetchExtension ( params: IFetchExtension ) : Promise<any> {
    console.log("FetchExtension.params: ", params);

    const baseUrl = `${CoreDefaults.api}/${params.protocol ?? CoreDefaults.fetchSettings.protocol}/${params.sourcePath}`;
    const url: string = OdataQueryComposer(baseUrl, params.queryOptions);
    // const language: (string|null) = localStorage.getItem("WebApplicationLanguage");

    const axiosConfig: AxiosRequestConfig = {
        url,
        method: params.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': params.contentType || 'application/json',
            'Accept-Language': params.language || CoreDefaults.clientLanguage.code
        },
        responseType: 'json',
        responseEncoding: 'utf8',
        validateStatus: function (status: number) {
            return CoreDefaults.acceptableHttpStatus.indexOf(status) > -1;
        }
    }

    // token
    // const token = cookies.get(CoreDefault.tokenKeyName);
    // if ( token && axiosConfig.headers ) { 
    //     axiosConfig.headers['Authorization'] = `Bearer ${ token }`; 
    // }

    // body
    if ( params.body || params.formData ) {
        axiosConfig.data = params.formData ? params.formData : JSON.stringify(params.body);
    }

    return Axios(axiosConfig);
}

export default FetchExtension;