import _ClientSilderItemServiceAction from "cms/actions/services/ClientSilderItemServiceAction";


const screenCode = "209";
class ClientSilderItemServiceAction extends _ClientSilderItemServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientSilderItemServiceAction;
