import _ClientMainPageItemsServiceAction from "cms/actions/services/ClientMainPageItemsServiceAction";


const screenCode = "207";
class ClientMainPageItemsServiceAction extends _ClientMainPageItemsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientMainPageItemsServiceAction;
