import FetchExtension from 'core/extensions/FetchExtension';
// service 
import ServiceBase from "core/services/ServiceBase";
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IOdataGetRequest } from 'core/interfaces/IGetRequest';
// enum
import HttpMethodEnum from 'core/enums/EHttpMethod';
import EndpointProtocol from 'core/enums/EEndpointProtocol';


class OdataCoreServiceBase extends ServiceBase {

	async get( params: IOdataGetRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
			// protocol: EndpointProtocol.ODATA,
            method: HttpMethodEnum.GET,
			language: params.language,
            
			sourcePath: this.sourcePath,
			queryOptions: params.queryOptions
		}

		return FetchExtension(apiFetchConfig);
	
    }

}

export default OdataCoreServiceBase;
