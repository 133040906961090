import React from "react";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// types
import { TMainPageLayout } from "../type";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
import RMDRImage from "components/RMDRImage";
import RMDRLink from "components/RMDRLink";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";


function mapStateToProps ( state: any ) {
    console.log("MNPG002.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG002 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG002.rendered", props);

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    const readMoreString = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingsDetailsPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "readMoreString" }
        ],
        type:"find"
    });

    return (
        <section className="ttm-row service-section-style2 res-991-pb-0 clearfix">
            <div className="container-xl">

                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title with-desc text-center clearfix">
                            <div className="title-header">
                                {
                                    parentLanguageVersion?.value && <h5 className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(parentLanguageVersion.value)}} />
                                }
                                <h2 className="title">{ parentLanguageVersion?.name }</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    {
                        props.childsPayload?.map((item) => {

                            const childLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type:"find"
                            });

                            const redirectURL = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                ],
                                type:"find"
                            });
                            
                            return (
                                <div key={item.id} className="col-md-6 col-lg-3">
                                    <div className="featured-icon-box style6">
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5 className="tw-line-clamp-2">{ childLanguageVersion?.name }</h5>
                                            </div>
                                            <div className="featured-desc ck-content tw-line-clamp-3" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(childLanguageVersion?.value)}} />
                                        </div>
                                        <div className="featured-icon">
                                            <RMDRImage
                                                resourceType={EResourceType.ICON} 
                                                resources={item.resources} 
                                                alt={childLanguageVersion?.name} 
                                                title={childLanguageVersion?.name} 
                                                className="ttm-icon ttm-icon_element-size-lg"
                                            />
                                        </div>
                                        <RMDRLink
                                            title={childLanguageVersion?.name}
                                            text={readMoreString?.value}

                                            hasNavigationDestination={item.hasDestinationNavigation}

                                            navigationURL={childLanguageVersion?.contentRouteNavigationURL}

                                            redirectType={item.redirectType}
                                            redirectUrl={redirectURL?.value}
                                            
                                            className="ttm-btn btn-inline style2"
                                        />
                                    </div>
                                </div>
                            );

                        })
                    }
                </div>

            </div>
        </section>
    )

}

const Component = React.memo(MNPG002, ( prevProps:TMainPageLayout, nextProps:TMainPageLayout ) => {
    console.log("MNPG002.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
