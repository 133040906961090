import OdataServiceBase from 'core/services/OdataServiceBase';


class ContentMetricsService extends OdataServiceBase {
	
    static code = "00A";
    code = "00A";

	constructor () {
		super({
			sourcePath: "content-metrics"
		});
	}
	
}

export default ContentMetricsService;
