import Lodash from 'lodash';
import CoreDefault from 'core/Defaults';
import Cookies from "universal-cookie";
// interface
import IBaseReduxStore from 'core/interfaces/IBaseReduxStore';
import IBaseReduxStoreAction from 'core/interfaces/IBaseReduxStoreAction';
import IBaseReduxStoreState from 'core/interfaces/IBaseReduxStoreState';
// enum
import EActionStatus from 'core/enums/EActionStatus';
import EActionType from 'core/enums/EActionType';
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';
// utilities
import changeValue from 'core/utilities/ReduxStoreModifierFunctions';


function BaseReduxStore ( { screenCode, serviceCode, protocol, initialState = EReduxStoreInitialState.DEFAULT}: IBaseReduxStore ) {

    // init state
    const initStateArray: IBaseReduxStoreState  = { message: null, payload: [], count: 0, error: null, isLoading: false }
    const initStateObject: IBaseReduxStoreState = { message: null, payload: {}, count: 0, error: null, isLoading: false }
    const initStateAny: IBaseReduxStoreState    = { message: null, payload: null, count: 0, error: null, isLoading: false }

    let initState: IBaseReduxStoreState = ((initialState === EReduxStoreInitialState.ARRAY && initStateArray) || (initialState === EReduxStoreInitialState.OBJECT && initStateObject) || (initialState === EReduxStoreInitialState.DEFAULT && initStateAny)) as IBaseReduxStoreState;

    // cookies
    let cookies:(Cookies|null) = null;
    if ( protocol === EActionType.SIGNIN ) {
        cookies = new Cookies();
    }

    return function ( state: IBaseReduxStoreState = initState, action: IBaseReduxStoreAction ) {

        switch (action.type) {
            
            /**
             * setter
             */
            // success
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.SUCCESS}`:
                if ( protocol === EActionType.SIGNIN ) {
                    let _token: string = action?.payload as string;

                    if ( !Lodash.isEmpty(_token) ) {
                        localStorage.setItem(CoreDefault.tokenKeyName, _token);
                        cookies?.set(CoreDefault.tokenKeyName, _token, { path: "/" });
                    }    
                }

                if ( protocol === EActionType.GET ) {
                    return {
                        ...state,
                        error: null,
                        isLoading: false,
                        ...action.extraPayload
                    }
                }
                else {
                    return {
                        ...state,
                        message: action.message,
                        payload: action.payload,
                        count: action.count || 0,
                        error: null,
                        isLoading: false,
                    }
                }
            
            // fail
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.FAIL}`:
                if ( protocol === EActionType.SIGNIN ) {
                    localStorage.clear();
                    cookies?.remove(CoreDefault.tokenKeyName, { path: "/" });
                }
                
                return {
                    ...state,
                    message: action.message,
                    payload: null,
                    count: 0,
                    error: action.payload,
                    isLoading: false
                };
            
            // store
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.STORE}`:
                return {
                    ...state,
                    message: !Lodash.isEmpty(action.message) ? action.message : state.message,
                    payload: !Lodash.isEmpty(action.payload) ? action.payload : state.payload,
                    count: Lodash.isNumber(action.count) ? action.count : state.count,
                    error: !Lodash.isEmpty(action.error) ? action.error : state.error,
                    isLoading: false
                };
            
            // modifier
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.MODIFIER}`:
                return {
                    ...state,
                    payload: changeValue(state?.payload, initialState, action?.payload),
                    isLoading: false
                };
            
            /**
             * cleaner
             */
            // clean
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.CLEAN}`:
                if ( protocol === EActionType.SIGNIN ) {
                    localStorage.clear();
                    cookies?.remove(CoreDefault.tokenKeyName, { path: "/" });
                }
                
                if ( protocol === EActionType.GET ) {
                    return {
                        ...state,
                        [action.key]: undefined
                    }
                }
                else {
                    if (initialState === EReduxStoreInitialState.ARRAY) return initStateArray;
                    else if (initialState === EReduxStoreInitialState.OBJECT) return initStateObject;
                    else if (initialState === EReduxStoreInitialState.DEFAULT) return initStateAny;
                    else return null;
                }
            
            // clean message
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.CLEAN_MESSAGE}`:
                return {
                    ...state,
                    message: null
                };
            
            // clean payload
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.CLEAN_PAYLOAD}`:
                return {
                    ...state,
                    payload: null
                };
            
            // clean error
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.CLEAN_ERROR}`:
                return {
                    ...state,
                    error: null
                };
            
            // clean count
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.CLEAN_COUNT}`:
                return {
                    ...state,
                    count: 0
                };


            /**
             * others
             */
            // fetching
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.FETCHING}`:
                return {
                    ...state,
                    isLoading: true
                };
            
            // toggle
            case `${screenCode}${serviceCode}${protocol}${EActionStatus.TOGGLE}`:
                return {
                    ...state,
                    payload: (state.payload === true || state.payload === false) ? !state.payload : state.payload,
                    isLoading: false
                };

            // default
            default:
                return state;
        }

    }

}

export default BaseReduxStore;
