// components
import RMDRImage from 'components/RMDRImage';
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import IClientContentDetail from "cms/interfaces/IClientContentDetail";
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import { EContentType } from 'cms/enums/EContentType';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import { dataTimeToHumanReadable } from 'core/utilities/DateTimeHelper';
import RMDRSocialShare from './RMDRSocialShare';


type TContentInnerHeader = {

    payload?: IClientContentDetail;
    metaInformationEnabled?: boolean;

}


function ContentInnerHeader ( props: TContentInnerHeader ) {
    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const dateTimeHR = dataTimeToHumanReadable(languageVersion?.creationDateTime);

    return (
        <>
            <RMDRImage
                resourceType={EResourceType.INNER_IMAGE}
                resources={props.payload?.resources} 
                alt={languageVersion?.name}
                title={languageVersion?.name} 
                className="w-100"
            />
            {
                props.metaInformationEnabled ? (
                    <>
                        <div className="ttm-post-entry-header">
                            <div className="post-meta tw-flex tw-justify-between">
                                <span className="ttm-meta-line entry-date">
                                {
                                    dateTimeHR ? (
                                        <>
                                            <i className="fa fa-calendar" />
                                            <time 
                                                className="entry-date published" 
                                                dateTime={languageVersion?.creationDateTime}
                                            >
                                                {dateTimeHR?.day} {dateTimeHR?.month} {dateTimeHR?.year}
                                            </time>
                                        </>
                                    ) : null
                                }
                                </span>
                                <span className="ttm-meta-line">
                                    <RMDRSocialShare 
                                        title={languageVersion?.name||""} 
                                        contentRouteNavigationURL={languageVersion?.contentRouteNavigationURL}
                                        itemClasssName="tw-inline tw-pl-5"
                                        iconClassName="tw-h-5 tw-w-5 tw-text-armadorColorLight-700"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="separator">
                            <div className="sep-line mt-3 mb-4" />
                        </div>
                    </>
                ) : null
            }
        </>
        
    )
}

export default ContentInnerHeader;
export type {
    TContentInnerHeader
};
