const EViewDataSource = {
    
    OUTTER      : { id: 1, name: "Outter" },
    INNER       : { id: 2, name: "Inner" },
    META_DATA   : { id: 4, name: "MetaData" }
    
}

enum EView {
    
    OUTTER = 1,
    INNER = 2,
    META_DATA = 4
    
}

export default EViewDataSource;
export {

    EView

}
