import React from "react";
import TMobileMenuHeaderComponent from "../types/TMobileMenuHeaderComponent";


function MobileMenuHeaderComponent (props:TMobileMenuHeaderComponent) {

    return (
        <div
            className="mobile-menu-button"
            onClick={() => props.handleSidebarToggle()}
        >
            <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
        </div>
    )

}

MobileMenuHeaderComponent.defaultProps = {
    logo: "",
    handleSidebarToggle: (event:any) => {},
}

export default MobileMenuHeaderComponent;
