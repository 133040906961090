import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/components/ShowroomLayouts/ClientPagesServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { TSHWRMLayout } from "../type";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IClientPageList from "cms/interfaces/IClientPageList";
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
import RMDRImage from "components/RMDRImage";
import { dataTimeToHumanReadable } from "core/utilities/DateTimeHelper";
import { Link } from "react-router-dom";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("SHWRM007.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const pageListPayload:IClientPageList[]|null    = state.ShowroomLayoutsStore.ClientPagesStore.list?.payload;
    const pageListError:TValidations[]|null         = state.ShowroomLayoutsStore.ClientPagesStore.list?.error;
    const pageListIsLoading:boolean                 = state.ShowroomLayoutsStore.ClientPagesStore.list?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        pageListPayload,
        pageListError,
        pageListIsLoading

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listPages: ( contentId:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`contentId eq ${contentId}`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 100
            };

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function SHWRM007 ( props: TSHWRMLayout<PropsFromRedux> ) {
    console.log("SHWRM007.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });
    
    useEffect(
        () => {

            props._listPages( props.contentDetailPayload.id, props.webApplicationLanguageCode );

            return function cleanup () {
                props._showLoader();
                props._clean();
            }
            
        }, []
    )

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

        }, [ props.pageListPayload ]
    )

    return (
        <div className="site-main">
            
            <ContentHeaderRow 
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload.resources}
                resourceType={EResourceType.PARALLAX}
            />
            
            <section className="ttm-row res-991-pt-0 clearfix">
                <div className="container-xl">
                    <div className="row">

                        {
                            props.pageListPayload?.map((item) => {
                                
                                const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                    languageVersions: item.languageVersions,
                                    filters: [
                                        { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                    ],
                                    type: "find"
                                });

                                const dateTimeHR = dataTimeToHumanReadable(languageVersion?.creationDateTime);

                                return (
                                    <div key={item.id} className="col-lg-6 col-sm-12 col-md-6 col-xs-12">
                                        <div className="row no-gutters featured-imagebox featured-imagebox-post ttm-box-view-left-image box-shadow">
                                            <div className="col-lg-5 col-md-12 col-sm-6 ttm-featured-img-left">
                                                <div className="featured-thumbnail">
                                                    <RMDRImage
                                                        resourceType={EResourceType.OUTER_IMAGE} 
                                                        resources={item.resources} 
                                                        alt={languageVersion?.name} 
                                                        title={languageVersion?.name} 
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-sm-6 featured-content">
                                                <span className="category">
                                                    {/* <a>Business</a>
                                                    <a>Consulting</a> */}
                                                </span>
                                                <div className="post-title featured-title">
                                                    <h5><Link title={languageVersion?.name||""} to={languageVersion?.contentRouteNavigationURL||""}>{languageVersion?.name}</Link></h5>
                                                </div>
                                                <div className="featured-desc ttm-box-desc ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                                                <div className="post-meta">
                                                    <span className="ttm-meta-line"><Link title={languageVersion?.name||""} to={languageVersion?.contentRouteNavigationURL||""}>{dateTimeHR?.day} {dateTimeHR?.month}, {dateTimeHR?.year}</Link></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        }

                    </div>
                </div>
            </section>
            
        </div>
    )
}

const Component = React.memo(SHWRM007, ( prevProps: TSHWRMLayout<PropsFromRedux>, nextProps: TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM007.memo", { prevProps, nextProps });
    return !(prevProps.pageListPayload !== nextProps.pageListPayload);
});

export default connector(Component);
