import { combineReducers } from 'redux';

// reducers
import ClientMainPageItemsServiceStore from "cms/reducers/services/ClientMainPageItemsServiceStore";
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";


const screenCode = "200";

const FooterStore = combineReducers({
 
    ClientMainPageItemsStore: ClientMainPageItemsServiceStore({ screenCode }),
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),

});

export default FooterStore;
