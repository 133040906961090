import React from "react";
import { isEmpty, orderBy } from "lodash";
// components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// enums
import { EViewType } from "cms/enums/EViewType";
// types
import { TRMDRTab } from "../type";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";


function RMDRTab03 ( props:TRMDRTab ) {
    console.log("RMDRTab03.rendered", props)

    if ( 
        !isEmpty(props.parentLanguageVersion?.id) && props.parentLanguageVersion?.id &&
        !isEmpty(props.parentLanguageVersion) && props.parentLanguageVersion &&
        props.parentLanguageVersion?.viewType === EViewType.TAB 
    ) {

        let payloadChild = props?.languageVersions?.filter((item) => item.parentId === props.parentLanguageVersion?.id);

        if ( isEmpty(payloadChild) ) return null;

        payloadChild = orderBy(payloadChild, ['order'],['asc']);

        return (
            <div className="col-12 pt-4 pb-2">
                {
                    props.parentLanguageVersion.value &&
                        <div className="section-title clearfix ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.parentLanguageVersion.value)}} />
                }

                <div className="ttm-tabs tabs-for-single-products p-0">
                    <Tabs>
                        <TabList>
                            {
                                payloadChild.map((item) => <Tab><span className="tw-font-semibold">{item.name}</span></Tab>)
                            }
                        </TabList>    
                        <div className="content-tab content-tab-style4">
                            {
                                payloadChild.map((item) => <TabPanel><div className="content-inner ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(item.value)}} /></TabPanel>)
                            }                            
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    
    }

    return null;
}

const Component = React.memo(RMDRTab03, ( prevProps:TRMDRTab, nextProps:TRMDRTab ) => {
    console.log("RMDRTab03.memo", { prevProps, nextProps });
    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.parentLanguageVersion !== nextProps.parentLanguageVersion
    );
});

export default Component;
