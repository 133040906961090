import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
import RMDRImage from "components/RMDRImage";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/components/ShowroomLayouts/ClientPagesServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientPageList from "cms/interfaces/IClientPageList";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { dataTimeToHumanReadable } from "core/utilities/DateTimeHelper";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// types
import TValidations from "cms/types/TValidations";
import { TSHWRMLayout } from "../type";


function mapStateToProps ( state: any ) {
    console.log("SHWRM006.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const pageListPayload:IClientPageList[]|null    = state.ShowroomLayoutsStore.ClientPagesStore.list?.payload;
    const pageListError:TValidations[]|null         = state.ShowroomLayoutsStore.ClientPagesStore.list?.error;
    const pageListIsLoading:boolean                 = state.ShowroomLayoutsStore.ClientPagesStore.list?.isLoading;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        pageListPayload,
        pageListError,
        pageListIsLoading,

        siteSettingsDetailsPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listPages: ( contentId:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`contentId eq ${contentId}`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 100
            };

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SHWRM006 ( props:TSHWRMLayout<PropsFromRedux> ) {
    console.log("SHWRM006.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    const readMoreString = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingsDetailsPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "readMoreString" }
        ],
        type:"find"
    });

    useEffect(
        () => {

            props._listPages( props.contentDetailPayload.id, props.webApplicationLanguageCode );

            return function cleanup () {
                props._showLoader();
                props._clean();
            }
            
        }, []
    )

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

        }, [ props.pageListPayload ]
    )

    return (
        <div className="site-main">
            
            <ContentHeaderRow 
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload.resources}
                resourceType={EResourceType.PARALLAX}
            />

            <section className="ttm-row res-991-pt-0 clearfix">
                <div className="container-xl">
                    <div className="row slick_slider ttm-boxes-spacing-30px">

                        {
                            props.pageListPayload?.map((item) => {
                                
                                const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                    languageVersions: item.languageVersions,
                                    filters: [
                                        { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                    ],
                                    type: "find"
                                });

                                const dateTimeHR = dataTimeToHumanReadable(languageVersion?.creationDateTime);

                                return (
                                    <div key={item.id} className="ttm-box-col-wrapper" style={{ width: "33%" }}>
                                        <div className="featured-imagebox featured-imagebox-blog">
                                            <div className="featured-thumbnail">
                                                <RMDRImage
                                                    resourceType={EResourceType.OUTER_IMAGE} 
                                                    resources={item.resources} 
                                                    alt={languageVersion?.name} 
                                                    title={languageVersion?.name} 
                                                    className="img-fluid"
                                                />
                                                <div className="ttm-blog-overlay-iconbox">
                                                    {/* <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a> */}
                                                </div>
                                                <div className="ttm-box-view-overlay" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="ttm-box-post-date">
                                                    <span className="ttm-entry-date">
                                                        <time className="entry-date" dateTime={languageVersion?.creationDateTime}>{dateTimeHR?.day}<span className="entry-month entry-year">{dateTimeHR?.month}</span></time>
                                                    </span>
                                                </div>
                                                <div className="featured-title">
                                                    <h5><Link title={languageVersion?.name||""} to={languageVersion?.contentRouteNavigationURL||""}>{languageVersion?.name}</Link></h5>
                                                </div>
                                                {/* <div className="post-meta">
                                                    <span className="ttm-meta-line"><i className="fa fa-comments" />2,comments</span>
                                                    <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                                                </div> */}
                                                <div className="featured-desc ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                                                <Link title={languageVersion?.name||""} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" to={languageVersion?.contentRouteNavigationURL||""}>{readMoreString?.value} <i className="ti ti-angle-double-right" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        }

                    </div>
                </div>
            </section>

        </div>
    )
}

const Component = React.memo(SHWRM006, ( prevProps:TSHWRMLayout<PropsFromRedux>, nextProps:TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM006.memo", { prevProps, nextProps });
    return !(prevProps.pageListPayload !== nextProps.pageListPayload);
});

export default connector(Component);
