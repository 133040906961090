import { combineReducers } from 'redux';

// reducers
import ClientSilderItemServiceStore from "cms/reducers/services/ClientSilderItemServiceStore";
import ClientCategoriesServiceStore from "cms/reducers/services/ClientCategoriesServiceStore";
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";


const screenCode = "209";

const CarouselStore = combineReducers({
 
    ClientSilderItemStore: ClientSilderItemServiceStore({ screenCode }),
    ClientCategoriesStore: ClientCategoriesServiceStore({ screenCode }),
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),

});

export default CarouselStore;
