import React from "react";
import { connect, ConnectedProps } from "react-redux"
import Lodash from 'lodash';
import { Navigate } from "react-router-dom";
import IClientLanguage from "core/interfaces/IClientLanguage";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationRedirector.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage|null    = state.CoreStore.language.payload;
    const webApplicationLanguageISOCode:string|null      = state.CoreStore.languageCode.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageISOCode
    
    }

}

function mapDispatchToProps ( dispatch: any ) {
    console.log("WebApplicationRedirector.mapDispatchToProps");

    return {

    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TWebApplicationRedirector<T> = {

} & T;


/* component */
function WebApplicationRedirector ( props:TWebApplicationRedirector<PropsFromRedux> ) {
    console.log("WebApplicationRedirector.rendered: ", props);

    if ( !Lodash.isEmpty(props.webApplicationLanguageISOCode) ) {

        return <Navigate to={`/${props.webApplicationLanguageISOCode}`} replace={true} />;
    
    }

    return null;

};

const Component = React.memo(WebApplicationRedirector, (prevProps:TWebApplicationRedirector<PropsFromRedux>, nextProps:TWebApplicationRedirector<PropsFromRedux>) => {
    console.log("WebApplicationRedirector.memo", { prevProps, nextProps });

    return !( 
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode 
        && !Lodash.isEmpty(nextProps.webApplicationLanguageISOCode)
    );

});

export default connector(Component);
