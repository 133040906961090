import { isEmpty } from 'lodash';
import CoreDefaults from 'core/Defaults';
// utilities
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
import ApplicationDocumentSelector, { TApplicationDocumentSelector } from 'cms/utilities/ApplicationDocumentSelector';


type TContentHeaderRow = {

    title?: string|null;
    description?: string|null;
    imagePath?: string|null;
    
} & TApplicationDocumentSelector;


function ContentHeaderRow ( props:TContentHeaderRow ) {

    let style = {}
    const applicationDocument = ApplicationDocumentSelector(props);

    if ( props.imagePath && !isEmpty(props.imagePath) ) {
        style = { backgroundImage: `url(${CoreDefaults.cdn}/${props.imagePath.replace("\\", "/")})` }
    }
    else if ( applicationDocument && !isEmpty(applicationDocument) ) {
        style = { backgroundImage: `url(${CoreDefaults.cdn}/${applicationDocument.filePath.replace("\\", "/")})` }
    }

    return (
        <div className="ttm-page-title-row" style={style}>
            <div className="container-xl">

                <div className="row">
                    <div className="col-md-12">
                        <div className="title-box text-start">
                            <div className="page-title-heading">
                                <h1 className="title">{props.title}</h1>
                            </div>
                            <div className="breadcrumb-wrapper">
                                <span className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.description)}} />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )

}

export default ContentHeaderRow;
export type {
    TContentHeaderRow
};
