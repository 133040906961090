import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientPagesService from 'cms/services/ClientPagesService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientPagesServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientPagesService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.DETAILS(),
        ...reduxStorages.GET()
    
    });
}

export default ClientPagesServiceStore;
