import React, { useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { Dispatch } from '@reduxjs/toolkit';
// components
import TopBar from "./components/TopBar";
import MiddleBar from "./components/MiddleBar";
import BottomBar from "./components/BottomBar";
// actions
import ClientMainPageItemsServiceAction from "cms/actions/components/Footer/ClientMainPageItemsServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import IClientLangauge from 'core/interfaces/IClientLanguage';
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
// enums
import ELayoutType from 'cms/enums/ELayoutType';
// types
import TValidations from 'cms/types/TValidations';
import { EContentStatus } from 'cms/enums/EContentStatus';


function mapStateToProps ( state: any ) {
    console.log("Footer.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLangauge    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const mainPageItemsListPayload:IClientMainPageItemList[]|null   = state.FooterStore.ClientMainPageItemsStore.list?.payload;
    const mainPageItemsListError:TValidations[]|null                = state.FooterStore.ClientMainPageItemsStore.list?.error;
    const mainPageItemsListIsLoading:boolean                        = state.FooterStore.ClientMainPageItemsStore.list?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        mainPageItemsListPayload,
        mainPageItemsListError,
        mainPageItemsListIsLoading,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const clientMainPageItemsServiceAction = new ClientMainPageItemsServiceAction(dispatch);

    return {
        // list
        _listClientMainPageItems: ( mapLayoutName:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `mapLayoutName eq '${mapLayoutName}'`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMainPageItemsServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TFooter<T> = {

    mapLayoutName:string;

} & T;


/* component */
function FooterLoader () {

    return (
        <></>
    )

}

function Footer ( props:TFooter<PropsFromRedux> ) {
    console.log("Footer.rendered: ", props);

    useEffect(
        () => {
            if ( !isEmpty(props.mapLayoutName) ) {
                props._listClientMainPageItems( props.mapLayoutName, props.webApplicationLanguageCode );
            }
        }, []
    )

    const parentItems = props.mainPageItemsListPayload?.filter((item) => item.parentId === null);

    if ( props.mainPageItemsListIsLoading ) {
        
        return <FooterLoader />

    }

    if ( !isEmpty(parentItems) && parentItems ) {

        return (
            <footer className="footer widget-footer clearfix">
                {
                    parentItems?.map((item, index:number) => {

                        const Layout = item.layouts.find((item) => item.layoutType === ELayoutType.MAIN_PAGE.id);

                        if ( Layout?.layoutName === "MNPG_007" ) {
                            return (
                                <TopBar
                                    payload={item}
                                    mainPageItemsPayload={props.mainPageItemsListPayload}
                                />
                            )
                        }
                        else if ( Layout?.layoutName === "MNPG_008" ) {
                            return (
                                <MiddleBar
                                    payload={item}
                                    mainPageItemsPayload={props.mainPageItemsListPayload}
                                />
                            )
                        }
                        else if ( Layout?.layoutName === "MNPG_009" ) {
                            return (
                                <BottomBar
                                    payload={item}
                                    mainPageItemsPayload={props.mainPageItemsListPayload}
                                />
                            )
                        }

                        return null;
                    })
                }
            </footer>
        );

    }

    return null;

}



const Component = React.memo(Footer, ( prevProps:TFooter<PropsFromRedux>, nextProps:TFooter<PropsFromRedux> ) => {
    console.log("MainPageItem.memo", { prevProps, nextProps });
    return !(prevProps.mainPageItemsListIsLoading !== nextProps.mainPageItemsListIsLoading );
});

export default connector(Component);
