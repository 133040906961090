import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';

const reduxStorages = new CoreReduxStore( { screenCode: "COM", serviceCode: "Lang" } as ICoreReduxStore )

/**
 * - Language api sinden alınan veriler burada depolanır.
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode COM(Common) ve serviceCode Lang(Langauge) olarak set edilmiştir.
 */

const LanguageStore = combineReducers({

    ...reduxStorages.LIST()

});

export default LanguageStore;