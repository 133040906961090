// action 
import OdataActionBase from "core/actions/OdataActionBase";
// service
import ContactUsService from "cms/services/ContactUsService";


class ContactUsServiceAction extends OdataActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ContactUsService, screenCode);
    }

}

export default ContactUsServiceAction;
