import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
//interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// styles
import './style.scss';
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import RMDRImage from "components/RMDRImage";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";


function mapStateToProps ( state: any ) {
    console.log("Preloader.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;
    
    const isLoading = !state.CoreStore.loader.isLoading;

    return {

        siteSettingDetailPayload,

        isLoading
    
    }
}

const connector = connect(mapStateToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Preloader ( props:PropsFromRedux ) {
    console.log("Preloader.rendered: ", props);

    const [ preloaderCSS, setPreloaderCSS ] = useState("");
    const [ showPreloader, setShowPreloader ] = useState(false);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect (
        () => {
            if ( props.isLoading === false ) {
                setTimeout( () => { setPreloaderCSS("animate__fadeOut"); }, 400 );
                setTimeout( () => { setShowPreloader(false); }, 600 );
            }
            else {
                setPreloaderCSS("");
                setShowPreloader(true);
            }
        }, [props.isLoading]
    )
    
    if ( showPreloader ) {
        document.body.classList.add('overflow-hidden');

        return (
            <div className={`preloader animate__animated animate__faster ${preloaderCSS}`}>
                <div className="loader-circle"></div>
                <div className="loader-line-mask">
                    <div className="loader-line"></div>
                </div>

                <RMDRImage
                    resources={props.siteSettingDetailPayload?.resources}
                    resourceType={EResourceType.LOGO_ONLY}
                    sizeType={ESizeType.X02}
                    alt={languageVersion?.name}
                    title={languageVersion?.name}
                />
                {/* <svg width="36px" height="36px" viewBox="0 0 36 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.98885921,23.8523026 C8.8942483,23.9435442 8.76801031,24 8.62933774,24 L2.03198365,24 C1.73814918,24 1.5,23.7482301 1.5,23.4380086 C1.5,23.2831829 1.55946972,23.1428989 1.65570253,23.0416777 L13.2166154,12.4291351 C13.3325814,12.3262031 13.4061076,12.1719477 13.4061076,11.999444 C13.4061076,11.8363496 13.3401502,11.6897927 13.2352673,11.587431 L1.68841087,0.990000249 C1.57298556,0.88706828 1.5,0.733668282 1.5,0.561734827 C1.5,0.251798399 1.73814918,2.85130108e-05 2.03198365,2.85130108e-05 L8.62933774,2.85130108e-05 C8.76855094,2.85130108e-05 8.89532956,0.0561991444 8.98994048,0.148296169 L21.4358709,11.5757407 C21.548593,11.6783875 21.6196864,11.8297916 21.6196864,11.999444 C21.6196864,12.1693815 21.5483227,12.3219261 21.4350599,12.4251432 L8.98885921,23.8523026 Z M26.5774333,23.8384453 L20.1765996,17.9616286 C20.060093,17.8578413 19.9865669,17.703871 19.9865669,17.5310822 C19.9865669,17.3859509 20.0390083,17.2536506 20.1246988,17.153855 L23.4190508,14.1291948 C23.5163648,14.0165684 23.6569296,13.945571 23.8131728,13.945571 C23.9602252,13.945571 24.0929508,14.0082997 24.1894539,14.1092357 L33.861933,22.9913237 C33.9892522,23.0939706 34.0714286,23.2559245 34.0714286,23.4381226 C34.0714286,23.748059 33.8332794,23.9998289 33.5394449,23.9998289 L26.9504707,23.9998289 C26.8053105,23.9998289 26.6733958,23.9382408 26.5774333,23.8384453 Z M26.5774333,0.161098511 C26.6733958,0.0615881034 26.8053105,0 26.9504707,0 L33.5394449,0 C33.8332794,0 34.0714286,0.251769886 34.0714286,0.561706314 C34.0714286,0.743904453 33.9892522,0.905573224 33.861933,1.00822006 L24.1894539,9.89030807 C24.0929508,9.99152926 23.9602252,10.0542579 23.8131728,10.0542579 C23.6569296,10.0542579 23.5163648,9.98354562 23.4190508,9.87063409 L20.1246988,6.8459739 C20.0390083,6.74617837 19.9865669,6.613878 19.9865669,6.46874677 C19.9865669,6.29624305 20.060093,6.14198767 20.1765996,6.03848544 L26.5774333,0.161098511 Z" fill="#FFFFFF"></path>
                </svg> */}
            </div>
        )
    }

    document.body.classList.remove('overflow-hidden');
    return null;

}

const Component = React.memo(Preloader, ( prevProps:PropsFromRedux, nextProps:PropsFromRedux ) => {
    console.log("Preloader.memo", { prevProps, nextProps });
    return !( 
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.siteSettingDetailPayload !== nextProps.siteSettingDetailPayload
    );
});

export default connector(Component);
