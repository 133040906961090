import React from "react";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// types
import { TMainPageLayout } from "../type";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// components
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EContentType } from "cms/enums/EContentType";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import RMDRImage from "components/RMDRImage";
import { EResourceType } from "cms/enums/EResourceType";


function mapStateToProps ( state: any ) {
    console.log("MNPG004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MNPG004 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG004.rendered", props);

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    return (
        <section className="ttm-row process-section clearfix">
            <div className="container-xl">

                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title text-center with-desc clearfix">
                            <div className="title-header">
                                {
                                    parentLanguageVersion?.value && <h5 className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(parentLanguageVersion.value)}} />
                                }
                                <h2 className="title">{ parentLanguageVersion?.name }</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="ttm-processbox-wrapper">
                            
                            {
                                props.childsPayload?.map((item, index) => {

                                    const childLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                        languageVersions: item.languageVersions,
                                        filters: [
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                        ],
                                        type:"find"
                                    });
                                    
                                    return (
                                        <div key={item.id} className="ttm-processbox text-center">
                                            <div className="ttm-box-image">
                                                <RMDRImage
                                                    resourceType={EResourceType.OUTER_IMAGE} 
                                                    resources={item.resources}
                                                    alt={childLanguageVersion?.name} 
                                                    className="img-fluid"
                                                    title={childLanguageVersion?.name}
                                                />
                                                <div className="process-num">
                                                    <span className="number">0{index+1}</span>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h5>{childLanguageVersion?.name}</h5></div>
                                                <div className="ttm-box-description ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(childLanguageVersion?.value)}} />
                                            </div>
                                        </div>
                                    );

                                })
                            }

                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );

}

const Component = React.memo(MNPG004, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG004.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
