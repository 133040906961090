import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ContentMetricsService from 'core/services/metrics/ContentMetricsService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ContentMetricsServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ContentMetricsService.code });

    return combineReducers({

        ...reduxStorages.POST()
    
    });
}

export default ContentMetricsServiceStore;
