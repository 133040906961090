import React from "react";
import { Link } from "react-router-dom";

type TPaginationItem = {

    value:number;
    currentPage:number;
    disabled:boolean;
    onClick:(event:React.MouseEvent<HTMLDivElement, MouseEvent>, value:number) => void;

    className?:string 
    activeClassName?:string;

}

function PaginationItem ( props:TPaginationItem ) {

    if (props.value === props.currentPage) {
        return (
            <div className={`${props.className} ${props.activeClassName}`} key={props.value}>{props.value}</div>
        );
    }

    return (
        <div onClick={event => { if(!props.disabled) props.onClick(event, props.value); }} className={props.className} key={props.value}>{props.value}</div>
    );

};


export default PaginationItem;
