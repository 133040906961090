import MNPG_001 from "./components/MNPG_001";
import MNPG_002 from "./components/MNPG_002";
import MNPG_003 from "./components/MNPG_003";
import MNPG_004 from "./components/MNPG_004";
import MNPG_005 from "./components/MNPG_005";
import MNPG_006 from "./components/MNPG_006";
import MNPG_010 from "./components/MNPG_010";
import MNPG_011 from "./components/MNPG_011";
import MNPG_012 from "./components/MNPG_012";
import MNPG_013 from "./components/MNPG_013";


const MainPageLayout:any = {
    
    MNPG_001,
    MNPG_002,
    MNPG_003,
    MNPG_004,
    MNPG_005,
    MNPG_006,
    MNPG_010,
    MNPG_011,
    MNPG_012,
    MNPG_013,

}

export default MainPageLayout;
