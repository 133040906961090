import React from "react";
// types
import { TFooterComponent } from "../type";
import MainPageLayouts from "components/MainPageLayouts";
import { EResourceType } from "cms/enums/EResourceType";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import CoreDefault from "core/Defaults";


const MiddleBar = ( props:TFooterComponent ) => {
    console.log("MiddleBar.rendered", props)

    const childsPayload = props.mainPageItemsPayload?.filter((item) => item.parentId === props.payload.id);

    console.log("childsPayload: ", childsPayload);

    const Layout01 = childsPayload?.[0]?.layouts?.[0]?.layoutName ? MainPageLayouts[childsPayload?.[0]?.layouts?.[0]?.layoutName]:null;
    const Layout02 = childsPayload?.[1]?.layouts?.[0]?.layoutName ? MainPageLayouts[childsPayload?.[1]?.layouts?.[0]?.layoutName]:null;
    const Layout03 = childsPayload?.[2]?.layouts?.[0]?.layoutName ? MainPageLayouts[childsPayload?.[2]?.layouts?.[0]?.layoutName]:null;

    const bgImage = ApplicationDocumentSelector({
        resources: props.payload.resources,
        resourceType: EResourceType.PARALLAX
    });

    return (
        <div className="second-footer ttm-textcolor-white" style={{backgroundImage: `url('${CoreDefault.cdn}/${bgImage?.filePath}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <div className="container-xl">
                <div className="row">

                    {
                        Layout01 ? (
                            <Layout01  parentPayload={childsPayload?.[0]} />
                        ) : null
                    }

                    {
                        Layout02 ? (
                            <Layout02  parentPayload={childsPayload?.[1]} />
                        ) : null
                    }

                    {
                        Layout03 ? (
                            <Layout03  parentPayload={childsPayload?.[2]} />
                        ) : null
                    }
                    
                </div>
            </div>
        </div>
    )
    
}

export default MiddleBar;
