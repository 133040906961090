import { isEmpty } from "lodash";
// interfaces
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import ESizeType from "cms/enums/ESizeType";
// interfaces
import IResource from 'cms/interfaces/IResource';


type TApplicationDocumentSelector = {

    parentResources?: IResource[];
    resources?: IResource[];
    
    resourceType?: EResourceType;
    sizeType?: ESizeType;

}

function ApplicationDocumentSelector ( props:TApplicationDocumentSelector ) {

    let applicationDocument:IApplicationDocument|null|undefined;

    let resource:IResource[]|undefined = undefined;

    if ( !isEmpty(props.parentResources) ) {
        resource = props.parentResources?.filter((item) => item.resourceTypeId === props.resourceType);
    }
    
    if ( isEmpty(resource) && !isEmpty(props.resources) ) {
        resource = props.resources?.filter((item) => item.resourceTypeId === props.resourceType);
    }

    if ( resource && !isEmpty(resource) ) {
            
        if ( props.sizeType !== undefined ) {
            applicationDocument = resource.find((item) => item?.applicationDocument.deriveds?.find((cItem) => cItem.sizeType === props.sizeType))?.applicationDocument?.deriveds?.[0];
        }

        if ( isEmpty(applicationDocument) ) {
            applicationDocument = resource?.[0]?.applicationDocument?.deriveds?.[0];
        }

        if ( isEmpty(applicationDocument) ) {
            applicationDocument = resource?.[0]?.applicationDocument;
        }

    }

    if ( !isEmpty(applicationDocument) && applicationDocument?.filePath?.match(/^\S+(.avif|.bmp|.gif|.jpg|.jpeg|.png|.svg|.tiff|.webp)/gm) ) {

        return applicationDocument;

    }

    return null;

}

export default ApplicationDocumentSelector;
export type {
    TApplicationDocumentSelector
};
