import FetchExtension from 'core/extensions/FetchExtension';
// service 
import OdataReadOnlyServiceBase from 'core/services/OdataReadOnlyServiceBase';
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IOdataPostRequest } from 'core/interfaces/IPostRequest';
import { IOdataDeleteRequest } from 'core/interfaces/IDeleteRequest';
// enum
import HttpMethodEnum from 'core/enums/EHttpMethod';
import EndpointProtocol from 'core/enums/EEndpointProtocol';


class OdataServiceBase extends OdataReadOnlyServiceBase {

	async post ( params: IOdataPostRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.POST,
			language: params.language,

			sourcePath: this.sourcePath,
			body: params.body,
		}

		return FetchExtension(apiFetchConfig);

	}

    async delete( params: IOdataDeleteRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.DELETE,
            language: params.language,

			sourcePath: `${this.sourcePath}/${params.key}`,
		}

		return FetchExtension(apiFetchConfig);
	
    }

}

export default OdataServiceBase;
