import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// reducers
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";
// enums
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


const screenCode = "20C";
const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: "" });

const SearchStore = combineReducers({
 
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),
    SearchPageStore: reduxStorages.BASE({ 
        storeKey: "search",
        protocolKey: "Se",
        initialState: EReduxStoreInitialState.OBJECT
    })["search"]
    
});

export default SearchStore;
