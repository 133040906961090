import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientCategoriesService from 'cms/services/ClientCategoriesService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientCategoriesServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientCategoriesService.code });

    return combineReducers({

        ...reduxStorages.DETAILS()
    
    });
}

export default ClientCategoriesServiceStore;
