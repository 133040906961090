import SHWRM_001 from "./components/SHWRM_001";
import SHWRM_002 from "./components/SHWRM_002";
import SHWRM_003 from "./components/SHWRM_003";
import SHWRM_004 from "./components/SHWRM_004";

import SHWRM_005 from "./components/SHWRM_005";
import SHWRM_006 from "./components/SHWRM_006";
import SHWRM_007 from "./components/SHWRM_007";


const ShowroomLayouts:any = {

    SHWRM_001,
    SHWRM_002,
    SHWRM_003,
    SHWRM_004,

    SHWRM_005,
    SHWRM_006,
    SHWRM_007,

}

export default ShowroomLayouts;
