import React from "react";
import { Provider } from "react-redux";
//
import AppReducer from "./appReducer";
import AppRoutes from "./appRoutes";


const store = AppReducer();

function App() {

    return (
        <Provider store={store}>
            <div className="page">
                <AppRoutes />
            </div>
        </Provider>
    );
    
}

export default App;
