import React from "react";
// components
import NormalMenu from 'components/Menu/NormalMenu';
import MobileMenu from 'components/Menu/MobileMenu';
import LanguageChooser from './LanguageChooser';
import ContentSearch from './ContentSearch';


function NavBar ( props:any ) {
    console.log("NavBar.rendered", props);

    return (
        <div id="navbar" className="ttm-stickable-header clearfix">
            <div className="ttm-bgcolor-grey">
                <div className="container-xl">
                    <div className="row site-header-menu">

                        <div className="col-12 site-navigation">
                            
                            <div className="ttm-right-header">
                                <div className="ttm-custombutton ttm-bgcolor-skincolor">

                                    <ul className="ttm-header-icons position-relative">
                                        <ContentSearch />
                                        <LanguageChooser />
                                    </ul>

                                </div>
                            </div>

                            <NormalMenu
                                navigation={props.navigation}
                            />
                            
                            <div className="mobilemenu">
                                <MobileMenu
                                    navigation={props.navigation}
                                />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
    
}

const Component = React.memo(NavBar, (prevProps:any, nextProps:any) => {
    console.log("NavBar.memo", { prevProps, nextProps });
    return !(
        prevProps.webApplicationLanguage !== nextProps.webApplicationLanguage
        || prevProps.navigation !== nextProps.navigation
    );
});

export default Component;
