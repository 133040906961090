const EResourceTypeDataSource = {
    
    PARALLAX                : {  id: 1, name: "Parallax" },
    PATTERN                 : {  id: 2, name: "Pattern" },
    OUTER_IMAGE             : {  id: 3, name: "OutterImage" },
    INNER_IMAGE             : {  id: 4, name: "InnerImage" },
    VIDEO                   : {  id: 5, name: "Video" },
    IMAGE                   : {  id: 6, name: "Image" },
    DATA                    : {  id: 7, name: "Data" },
    STATISTIC_DATA_SHEET    : {  id: 8, name: "StatisticDataSheet" },
    ICON                    : {  id: 9, name: "Icon" },
    LOGO_DEFAULT            : {  id: 10, name: "LogoDefault" },
    LOGO_ONLY               : {  id: 11, name: "LogoOnly" },
    LOGO_WITH_BOTTOM_TEXT   : {  id: 12, name: "LogoWithBottomText" }

}

enum EResourceType {
    
    PARALLAX = 1,
    PATTERN,
    OUTER_IMAGE,
    INNER_IMAGE,
    VIDEO,
    IMAGE,
    DATA,
    STATISTIC_DATA_SHEET,
    ICON,
    LOGO_DEFAULT,
    LOGO_ONLY,
    LOGO_WITH_BOTTOM_TEXT,

}

export default EResourceTypeDataSource;
export {
    EResourceType
}
