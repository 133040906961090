import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
import { ToastContainer } from 'react-toastify';
// components
import WebApplicationRedirector from "components/WebApplicationRedirector";
import WebApplicationStarter from "components/WebApplicationStarter";
import Preloader from "components/Preloader";
// layouts
import WebApplicationLayout from "cms/layouts/WebApplicationLayout";
// screens
import Home from "screens/Home";
import Search from "screens/Search";
import Content from "screens/Content";
import Error404 from "screens/Error404";


function mapStateToProps ( state: any ) {
    console.log("AppRoutes.mapStateToProps: ", state);

    return {

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("AppRoutes.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TAppRoutes<T> = {

} & T;


const AppRoutes = ( props:TAppRoutes<PropsFromRedux> ) => {
    console.log("AppRoutes.rendered: ", props);

    return (
        <BrowserRouter>
            <Preloader />
            
            <Routes>
                <Route path="/" element={<WebApplicationStarter />} >

                    {/* redirect: language */}
                    <Route index element={<WebApplicationRedirector />} />
                    <Route path="index" element={<WebApplicationRedirector />} />
                    <Route path="home" element={<WebApplicationRedirector />} />

                    {/* redirect: pages */}
                    <Route path=":language" element={<WebApplicationLayout />}>
                        <Route index element={<Home />} />
                        <Route path="search" element={<Search />} />
                        <Route path=":contentName/:contentId" element={<Content />} />

                        {/* redirect: page not fount  */}
                        <Route path="*" element={<Error404 />} />
                    </Route>
                    
                </Route>
            </Routes>

            <ToastContainer />
        </BrowserRouter>
    );

};

const Component = React.memo(AppRoutes, ( prevProps:TAppRoutes<PropsFromRedux>, nextProps:TAppRoutes<PropsFromRedux> ) => {
    console.log("AppRoutes.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
