// components
import RMDRImage from "components/RMDRImage";
// types
import { TFooterComponent } from "../type";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import CoreDefault from "core/Defaults";


const TopBar = ( props:TFooterComponent ) => {
    console.log("TopBar.rendered: ", props);

    const childsPayload = props.mainPageItemsPayload?.filter((item) => item.parentId === props.payload.id);
    const bgImage = ApplicationDocumentSelector({
        resources: props.payload.resources,
        resourceType: EResourceType.PARALLAX
    });
    
    return (
        <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes" style={{backgroundImage: `url('${CoreDefault.cdn}/${bgImage?.filePath}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />

            <div className="container-xl">
                <div className="row align-items-md-center">
                    <div className="footer-logo text-center">
                        <RMDRImage
                            resourceType={EResourceType.OUTER_IMAGE} 
                            resources={props.payload.resources}
                            alt={"ARMADOR Logo"}
                            title={"ARMADOR Logo"} 
                            className="img-fluid"

                            width={"100%"}
                            height={"100%"}
                        />
                    </div>
                </div>
            </div>
            
        </div>
    )
    
}

export default TopBar;
