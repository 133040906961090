import { combineReducers } from 'redux';

/* Common */
import CoreStore from "core/reducers/common/CoreStore";
import LanguageStore from "core/reducers/common/LanguageStore";
import MessagePublisherStore from "core/reducers/common/MessagePublisherStore";
import PopupPublisherStore from "core/reducers/common/PopupPublisherStore";


const CoreReducer = {

    /* Common */
    CoreStore,
    LanguageStore,
    MessagePublisherStore,
    PopupPublisherStore

}

export default CoreReducer;
