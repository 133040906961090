/* Common */
import SiteSettingStore from "cms/reducers/common/SiteSettingStore";

/* Components */
import HeaderStore from "cms/reducers/components/HeaderStore";
import FooterStore from "cms/reducers/components/FooterStore";
import SliderStore from "cms/reducers/components/SliderStore";
import MainPageItemStore from "cms/reducers/components/MainPageItemStore";
import LatestContents from "cms/reducers/components/LatestContents";
// InnerPageLayouts
import INNR003Store from "cms/reducers/components/InnerLayouts/INNR003Store";
// ShowroomLayouts
import ShowroomLayoutsStore from "cms/reducers/components/ShowroomLayoutsStore";

/* Screens */
import ContentStore from "cms/reducers/screens/ContentStore";
import SearchStore from "cms/reducers/screens/SearchStore";

/* Services */


const CMSReducers = {

    /* Common */
    SiteSettingStore,

    /* Components */
    HeaderStore,
    FooterStore,
    SliderStore,
    MainPageItemStore,
    LatestContents,
    // 
    INNR003Store,
    //
    ShowroomLayoutsStore,

    /* Screens */
    ContentStore,
    SearchStore,
    
    /* Services */

}

export default CMSReducers;
