import ActionCore from 'core/actions/ActionCore';
// enum
import EActionStatus from "core/enums/EActionStatus";


const screenCode = "20C";
class SearchPageAction extends ActionCore {

    constructor( dispatch:any ) {
        super( dispatch, null, screenCode );
    }

    async modify ( payload: any ) {

        super.modify("Se", payload);

    }

    clean () {
        
        this.dispatch({ type: `${this.screenCode}Se${EActionStatus.CLEAN}` });

    }

}

export default SearchPageAction;
