import React from "react";
import cn from "classnames";
import {
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";
import {
    IoLogoFacebook,
    IoLogoLinkedin,
    IoLogoWhatsapp
} from 'react-icons/io5';
import {
    FaXTwitter
} from 'react-icons/fa6'

type TRMDRSocialShare = {

    className?:string;
    itemClasssName?:string;
    shareButtonClasssName?:string;
    iconClassName?:string;

    title:string;
    contentRouteNavigationURL?:string|null;

}


function RMDRSocialShare ( props:TRMDRSocialShare ) {

    return (
        <ul className={cn("rmdr-social-share", props.className)}>
            <li className={cn("rmdr-social-share-item", props.itemClasssName)}>
                <FacebookShareButton
                    url={`${window.location.origin}${props?.contentRouteNavigationURL}`}
                    title={props.title}
                    windowHeight={600}
                    windowWidth={800}
                    className={props.shareButtonClasssName}
                >
                    <IoLogoFacebook className={props.iconClassName} />
                </FacebookShareButton>
            </li>
            <li className={cn("rmdr-social-share-item", props.itemClasssName)}>
                <TwitterShareButton
                    url={`${window.location.origin}${props?.contentRouteNavigationURL}`}
                    title={props.title}
                    windowHeight={600}
                    windowWidth={800}
                    className={props.shareButtonClasssName}
                >
                    <FaXTwitter className={props.iconClassName} />
                </TwitterShareButton>
            </li>
            <li className={cn("rmdr-social-share-item", props.itemClasssName)}>
                <LinkedinShareButton
                    url={`${window.location.origin}${props?.contentRouteNavigationURL}`}
                    title={props.title}
                    windowHeight={600}
                    windowWidth={800}
                    className={props.shareButtonClasssName}
                >
                    <IoLogoLinkedin className={props.iconClassName} />
                </LinkedinShareButton>
            </li>
            <li className={cn("rmdr-social-share-item", props.itemClasssName)}>
                <WhatsappShareButton
                    url={`${window.location.origin}${props?.contentRouteNavigationURL}`}
                    title={props.title}
                    separator=":: "
                    windowHeight={600}
                    windowWidth={800}
                    className={props.shareButtonClasssName}
                >
                    <IoLogoWhatsapp className={props.iconClassName} />
                </WhatsappShareButton>
            </li>
        </ul>
    )

}

export default RMDRSocialShare;