import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import ContentRenderer from "components/ContentRenderer";
import ContentHeaderRow from "components/ContentHeaderRow";
import ContentInnerHeader from "components/ContentInnerHeader";
import { TINNRLayout } from "components/InnerLayouts/type";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
// actions
import CoreAction from "core/actions/common/CoreAction";


function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);

    return {
        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        }
    }

}

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR002 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR002.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({ 
        languageVersions:props.contentDetailPayload?.languageVersions, 
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY },
        ],
        type:"find"
    });

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

            return function cleanup() {
                props._showLoader();
            }

        }, [ ]
    )

    return (
        <>
            <ContentHeaderRow 
                title={languageVersion?.name} 
                description={languageVersion?.value}
                resources={props.contentDetailPayload.resources}
                resourceType={EResourceType.PARALLAX}
            />

            <section className="ttm-row project-single-section clearfix">
                <div className="container-xl">
                    <ContentInnerHeader payload={props.contentDetailPayload} metaInformationEnabled={true} />
                    <ContentRenderer languageVersions={props.contentDetailPayload?.languageVersions} />
                </div>
            </section>
        </>
    )
}

const Component = React.memo(INNR002, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR002.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
