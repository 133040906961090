// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientMainPageItemsService from "cms/services/ClientMainPageItemsService";


class ClientMainPageItemsServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientMainPageItemsService, screenCode);
    }

}

export default ClientMainPageItemsServiceAction;
