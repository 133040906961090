import OdataServiceBase from "core/services/OdataServiceBase";


class ContactUsService extends OdataServiceBase {

    static code = "207";
    code = "207";

    constructor () {

        super( {
            sourcePath: "contact-us"
        });

    }
    
}

export default ContactUsService;
