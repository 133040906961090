import { CSSProperties, JSXElementConstructor, ReactNode } from "react";
import { isEmpty, isNaN, isNumber, reject } from "lodash";
import { NavLink } from "react-router-dom";
// enums
import ERedirectType from 'cms/enums/ERedirectType';


type Variant = 'a' | 'div';

type TRMDRNavLink = {

    text?: string|null;
    title?: string|null;

    hasNavigationDestination?: boolean|null;
    hasActive?: boolean|null;
    isShowAlways?: boolean|null;

    navigationURL?: string|null;

    redirectType?: ERedirectType;
    redirectUrl?: string|null;

    className?: string;
    activeClassName?: string;
    style?: CSSProperties;

    children?: ReactNode;

    onClick?: (event:any) => void;

    variant?: Variant;
}

function RMDRNavLink ( props:Readonly<TRMDRNavLink> ) {
    
    const _target = ((props.redirectType === ERedirectType.STAY_CURRENT_PAGE || !isNumber(props.redirectType)) && "_self") || (props.redirectType === ERedirectType.REDIRECT_NEW_TAB && "_blank") || "";

    if ( props.hasNavigationDestination && !isEmpty(props.redirectUrl) && props.redirectUrl ) {

        return <a 
            className={props.className} 
            style={props.style}
            href={props.redirectUrl}
            target={_target}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.children, props.redirectUrl], isEmpty )?.[0] }
        </a>

    }

    if ( props.hasNavigationDestination && !isEmpty(props.navigationURL) && props.navigationURL ) {

        return <NavLink 
            className={({ isActive, isPending }) => `${props.className} ${isActive||props.hasActive?props.activeClassName:""}`} 
            style={props.style}
            to={props.navigationURL}
            target={_target}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.children, props.navigationURL], isEmpty )?.[0] }
        </NavLink>

    }

    if ( !isEmpty(props.onClick) ) {
        
        return <NavLink
            className={({ isActive, isPending }) => `${props.className} ${isActive||props.hasActive?props.activeClassName:""}`} 
            style={props.style}
            to={"#"}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.children], isEmpty )?.[0] }
        </NavLink>

    }

    if ( props.isShowAlways ) {

        const componentsMap: {
            [P in Variant]: React.ComponentType<any> | string;
        } = {
            a: 'a',
            div: 'div'
        };
    
        const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = componentsMap![props.variant!];

        return <Component 
            className={props.className}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.redirectUrl, props.children], isEmpty )?.[0] }
        </Component>;
        
    }
        
    return null;

}

RMDRNavLink.defaultProps = {

    hasNavigationDestination: false,
    hasActive: false,
    isShowAlways: false,
    className : "",
    activeClassName : "",
    variant: "a"

};

export default RMDRNavLink;
export type {
    TRMDRNavLink
};
