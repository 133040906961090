const ELayoutTypeDataSource = {

    MENU_ITEM       : { id: 1, name: "MenuItem" },
    MENU_GROUP      : { id: 2, name: "MenuGroup" },
    INNER_PAGE      : { id: 3, name: "InnerPage" },
    OUTER_PAGE      : { id: 4, name: "OutterPage" },
    SLIDER          : { id: 5, name: "Slider" },
    SLIDER_GROUP    : { id: 6, name: "SliderGroup" },
    SHOWROOM        : { id: 7, name: "Showroom" },
    GALLERY         : { id: 8, name: "Gallery" },
    MAIN_PAGE       : { id: 9, name: "MainPage" },
    MAIN_PAGE_GROUP : { id: 10, name: "MainPageGroup" },
    STATISTIC_GROUP : { id: 11, name: "StatisticGroup" } 

}

enum ELayoutType {

    MENU_ITEM = 1,
    MENU_GROUP,
    INNER_PAGE,
    OUTER_PAGE,
    SLIDER,
    SLIDER_GROUP,
    SHOWROOM,
    GALLERY,
    MAIN_PAGE,
    MAIN_PAGE_GROUP,
    STATISTIC_GROUP

}

export default ELayoutTypeDataSource;
export {

    ELayoutType

}
