const EContentStatusDataSource = {
    
    PASSIVE:    { "id": 0, "name": "Passive", "description": null }, 
    ACTIVE:     { "id": 1, "name": "Active", "description": null },
    PREVIEW:    { "id": 2, "name": "Preview", "description": null }

}

enum EContentStatus {
    
    PASSIVE,
    ACTIVE,
    PREVIEW

}


export default EContentStatusDataSource;
export {

    EContentStatus

}
