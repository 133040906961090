import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Link, useSearchParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import SEO from "components/SEO";
import RMDRImage from "components/RMDRImage";
import NoData from "components/NoData";
import Pagination from "components/Pagination";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/screens/Search/ClientPagesServiceAction";
import SearchPageAction from "cms/actions/screens/Search/SearchPageAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientPageList from "cms/interfaces/IClientPageList";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// utilities
import { dataTimeToHumanReadable } from "core/utilities/DateTimeHelper";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
// enums
import { EContentType } from "cms/enums/EContentType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentStatus } from "cms/enums/EContentStatus";
// types
import TValidations from "cms/types/TValidations";
// styles
import { HiOutlineNewspaper } from "react-icons/hi2";



function mapStateToProps ( state: any ) {
    console.log("Search.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    const clientPageListPayload:IClientPageList[]|null      = state.SearchStore.ClientPagesStore.list.payload;
    const clientPageListCount:number                        = state.SearchStore.ClientPagesStore.list?.count;
    const clientPageListError:TValidations[]|never          = state.SearchStore.ClientPagesStore.list.error;
    const clientPageListIsLoading:boolean                   = state.SearchStore.ClientPagesStore.list.isLoading;

    const searchPagePayload:any                             = state.SearchStore.SearchPageStore.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        searchPagePayload,
        
        clientPageListPayload,
        clientPageListCount,
        clientPageListError,
        clientPageListIsLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);
    const searchPageAction = new SearchPageAction(dispatch);

    return {
        _modifySearchPageStore: ( payload:any ) => {
            searchPageAction.modify(payload);
        },
        _setSearchInit: ({searchString, orderBy, itemsPerPage, currentPage, hasSearch}:any) => {
            searchPageAction.modify([
                {key:"searchString", value: searchString},
                {key:"orderBy", value: orderBy},
                {key:"itemsPerPage", value: itemsPerPage},
                {key:"currentPage", value: currentPage},
                {key:"hasSearch", value: hasSearch},
                {key:"hasFirstSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },
        _setSearchString: ( value:string ) => {
            searchPageAction.modify([
                {key:"searchString", value: value}
            ]);
        },
        _setHasSearch: ( value:boolean = true, isNewSearch:boolean = false ) => {
            let _payload:any = [
                {key:"hasSearch", value},
            ]
            if (isNewSearch) {
                _payload = [
                    {key:"orderBy", value: "creationDateTime"},
                    {key:"currentPage", value: 1},
                    {key:"hasFirstSearch", value: true},
                    {key:"scrollTo", value: true},
                    ..._payload
                ]
            }
            
            searchPageAction.modify(_payload);
        },
        _setFirstSearch: ( value:boolean ) => {
            searchPageAction.modify([
                {key:"hasFirstSearch", value}
            ]);
        },
        _setScrollTo: ( value:boolean ) => {
            searchPageAction.modify([
                {key:"scrollTo", value},
            ]);
        },
        _setLastPage: ( lastPage:number ) => {
            searchPageAction.modify([
                {key:"lastPage", value: lastPage},
            ]);
        },
        _setCurrentPage: ( currentPage:number ) => {
            searchPageAction.modify([
                {key:"currentPage", value: currentPage},
                {key:"hasSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },
        _search: ( payload:any, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`],
                count: true,
                top: payload.itemsPerPage,
                skip: (payload.currentPage-1) * payload.itemsPerPage
            };
            
            if(!isEmpty(payload.filters) && payload.filters) {
                queryOptions.filter?.push("and");
                queryOptions.filter = [...queryOptions?.filter||[], ...payload.filters];
            }

            if ( payload.orderBy && !isEmpty(payload.orderBy) ) {
                queryOptions["orderBy"] = [payload.orderBy]
            }

            clientPagesServiceAction.search({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        _cleanSearch: () => {
            clientPagesServiceAction.listClean();
        },
        
        // others
        _hideLoader: () => { 
            coreAction.hideLoader();
        },
        _showLoader: () => { 
            coreAction.showLoader();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TSearch<T> = {

} & T;


/* component */
function SearchLoader () {

    return (
        <>
            {
                Array.from({ length:9 }).map(() => (
                    <div className="tw-w-1/3 tw-p-1">
                        <div className="tw-animate-pulse tw-bg-gray-100 tw-h-48" />
                    </div>
                ))
            }
        </>
    )

}

function Search ( props:TSearch<PropsFromRedux> ) {
    console.log("Search.rendered: ", props);

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ init ] = useState([
        {key:"orderBy", value: "creationDateTime desc"},
        {key:"itemsPerPage", value: 12},
        {key:"currentPage", value: 1},
        {key:"scrollTo", value: true},
        {key:"hasSearch", value: true},
    ]);

    const readMoreString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "readMoreString" }
        ],
        type:"find"
    });

    const resultsFoundString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "resultsFoundString"}
        ],
        type:"find"
    });

    const searchPlaceholderString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "searchPlaceholderString"}
        ],
        type:"find"
    });

    	
    useEffect(
        () => {
            return function cleanup () {
                props._showLoader();
                props._cleanSearch();
            }
        }, []
    )

    useEffect(
        () => {
            let filters: string[] = [];

            const _searchString = searchParams.get("q")?.trim()?.toLowerCase();
            const _orderBy = searchParams.get("o")?.trim();
            let _currentPage = parseInt(searchParams.get("cp")?.trim()||"1");
            if ( isNaN(_currentPage) ) {
                _currentPage = 1;
            }

            // string filter
            if ( _searchString && !isEmpty(_searchString) ) {
                if ( filters.length > 0 ) filters.push("and");
                filters.push(`(languageVersions/any(d: contains(tolower(d/value),'${_searchString}')) or languageVersions/any(d: contains(tolower(d/name),'${_searchString}')))`);
            }

            if ( isEmpty(props.searchPagePayload) ) {
                let _init = [
                    ...init,
                    { key:"searchString", value: _searchString },
                    { key:"filters", value: filters },
                    { key:"orderBy", value: _orderBy },
                    { key:"currentPage", value: _currentPage },
                ];
                props._modifySearchPageStore(_init);
            }
            else {
                props._modifySearchPageStore([
                    { key:"searchString", value: _searchString },
                    { key:"hasSearch", value: true },
                    { key:"filters", value: filters },
                    { key:"orderBy", value: _orderBy },
                    { key:"currentPage", value: _currentPage },
                ]);
            }
        }, [ searchParams ]
    )

    useEffect(
        () => {
            if (props.searchPagePayload?.hasSearch) {
                props._setHasSearch(false);
                props._search(props.searchPagePayload, props.webApplicationLanguageCode);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        }, [props.searchPagePayload]
    )
    
    useEffect(
        () => {
            // if (props.clientPageListPayload?.scrollTo) {
                // setTimeout(
                //     () => {
                //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                //     }, 200
                // );
                props._setFirstSearch(false);
                // props._setScrollTo(false);

                setTimeout(() => { props._hideLoader() }, 100);
            // }
        }, [props.clientPageListPayload]
    )

    useEffect(
        () => {
            try {
                let _lastPage = Math.ceil(props.clientPageListCount/props.searchPagePayload.itemsPerPage);
                props._setLastPage(_lastPage);
            }
            catch(ex) {}
        }, [props.clientPageListCount]
    )

    function onSubmit(event:any) {
    
        event.preventDefault();

        const target = event.target as typeof event.target & {
            queryString: { value: string }
        };

        searchParams.set("q", target.queryString.value );
        searchParams.set("cp", "1" );
        setSearchParams(searchParams);
    
    }

    return (
        <div className="site-main">
            
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="showroom-page" />

            <div className="ttm-page-title-row" style={{backgroundImage: "url('/images/search-wallpaper-4.webp')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
                <div className="container-xl">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-start">
                                <div className="page-title-heading">
                                    <h1 className="title text-white">{ props.searchPagePayload?.searchString || " " }</h1>
                                    { 
                                        !isEmpty(props.searchPagePayload?.searchString) || props.clientPageListCount > 0 ? (
                                            <p className="text-white">
                                                <strong>{ props.clientPageListCount }</strong>{" "}
                                                {resultsFoundString?.value}
                                            </p>
                                        ) : null
                                    }
                                </div>
                                <div className="breadcrumb-wrapper">
                                    <form className="d-flex flex-row gap-3 p-3 tw-bg-white tw-rounded-xl" onSubmit={onSubmit}>
                                        <input name="queryString" type="search" className="field searchform-s flex-grow-1 tw-border-none focus:tw-border-none" placeholder={searchPlaceholderString?.value||""} defaultValue={searchParams.get("q")?.trim()} />
                                        <button type="submit" className="tw-border-none tw-bg-transparent"><i className="ti ti-search" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <section className="ttm-row res-991-pt-0 clearfix">
                <div className="container-xl">
                    <div className="row ttm-boxes-spacing-30px">
                        
                        {
                            !props.clientPageListIsLoading && isEmpty(props.clientPageListPayload) ? (
                                <NoData
                                    icon={<HiOutlineNewspaper className="tw-h-40 tw-stroke-1 tw-text-9xl" />}
                                    className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                                    textClassName="tw-text-center tw-text-2xl"
                                    languageVersions={props.siteSettingDetailPayload.languageVersions}
                                />
                            ) : null
                        }

                        {
                            !props.clientPageListIsLoading && !isEmpty(props.clientPageListPayload) ?
                                (
                                    props.clientPageListPayload?.map((item) => {
                                
                                        const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                            languageVersions: item.languageVersions,
                                            filters: [
                                                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                            ],
                                            type: "find"
                                        });

                                        const dateTimeHR = dataTimeToHumanReadable(item?.creationDateTime);
                                        console.log("dateTimeHR: ", dateTimeHR);

                                        return (
                                            <div key={languageVersion?.id||""} className="ttm-box-col-wrapper tw-w-full md:tw-w-1/3">
                                                <div className="featured-imagebox featured-imagebox-blog">
                                                    <div className="featured-content !tw-p-6" >
                                                        <div className="featured-title">
                                                            <h5>
                                                                <Link 
                                                                    title={languageVersion?.name||""}
                                                                    to={languageVersion?.contentRouteNavigationURL||""}
                                                                    className="tw-line-clamp-2 tw-h-14"
                                                                >
                                                                        {languageVersion?.name}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                        <div className="post-meta">
                                                            <span className="ttm-meta-line"><i className="fa fa-calendar-alt" />{`${dateTimeHR?.day} ${dateTimeHR?.month} ${dateTimeHR?.year}`}</span>
                                                        </div>
                                                        <div className="featured-desc ck-content tw-line-clamp-2" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                                                        <Link title={languageVersion?.name||""} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" to={languageVersion?.contentRouteNavigationURL||""}>
                                                            {readMoreString?.value} 
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                ) : null
                        }
                        {
                            props.clientPageListIsLoading?
                                (
                                    <SearchLoader />
                                ) : null
                        }

                    </div>
                </div>
            </section>

            {
                props.searchPagePayload.lastPage > 1 &&
                    <Pagination pageSize={props.searchPagePayload.lastPage} disabled={props.clientPageListIsLoading} className="tw-mb-28" />
            }

        </div>            
    )
}

const Component = React.memo(Search, ( prevProps:TSearch<PropsFromRedux>, nextProps:TSearch<PropsFromRedux> ) => {
    console.log("Search.memo", { prevProps, nextProps });
    return !(
        prevProps.searchPagePayload !== nextProps.searchPagePayload
        || prevProps.clientPageListPayload !== nextProps.clientPageListPayload
    )
});

export default connector(Component);
