import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
// import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// types
import { TClientMenuItemList } from "../../type";
import { TMobileMenuMenuComponent } from "../types/TMobileMenuMenuComponent";
// components
import RMDRNavLink from "components/RMDRNavLink";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { EContentType } from "cms/enums/EContentType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { isEmpty } from "lodash";
import { FaLanguage } from "react-icons/fa6";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'


function mapStateToProps ( state: any ) {
    console.log("MobileMenuMenuComponent.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const languagePayload:IClientLanguage[] = state.LanguageStore.list?.payload;

    return {
        
        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        languagePayload,

    }

}

const connector = connect(mapStateToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function MobileMenuMenuComponent ( props:TMobileMenuMenuComponent<PropsFromRedux> ) {

    const history = useNavigate();
    const [menuOpened, setMenuOpened] = useState<string[]>([]);
    const [selected, setSelected] = useState<IClientLanguage|undefined>(undefined);
    const searchInputRef = useRef<any>(null);
    
    useEffect(
        () => {
            if (props.toggleCollapse) {
                setMenuOpened([]);
            }
        }, [props.toggleCollapse]
    )

    useEffect(
        () => {

            if ( !isEmpty(props.webApplicationLanguage) && props.languagePayload && !isEmpty(props.languagePayload) ) {
                const langauge = props.languagePayload.find((item) => item.id === props.webApplicationLanguage.id);
                if ( !isEmpty(langauge) ) setSelected(langauge)
            }

        }, [props.webApplicationLanguage]
    )

    function setMenuStatus( menuId:string ) {
        
        setMenuOpened((prevState:any) => ([...prevState.includes(menuId) ? prevState.filter((item:any) => item !== menuId) : new Set([...prevState, menuId])]) as never);
    
    }

    function search( event:any ) {

        event.preventDefault();

        if ( searchInputRef?.current ) {
            history({
                pathname: `/${props.webApplicationLanguageISOCode}/search`,
                search: `?query=${searchInputRef.current?.value}`, 
            });
        }

    }

    function clear( event:any ) {

        event.preventDefault();

        if ( searchInputRef?.current ) {
            searchInputRef.current.value = "";
        }

    }

    function onKeyDown ( event:any ) {
        
        if ( event.key === "Enter" ) {
            search(event);
            props.handleSidebarToggle();
        }

    }

    // components
    function MenuParent(payload:{item:TClientMenuItemList, _key?:any}) {

        const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
            languageVersions: payload.item.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: 'find'
        });

        const redirectUrl = LanguageVersionSelector<ILanguageVersionList|undefined>({
            languageVersions: payload.item.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
            ],
            type: 'find'
        });

        return (
            <div key={payload.item.id} className={"tw-flex tw-items-center tw-cursor-pointer hover:tw-bg-tuna-800 tw-w-full tw-overflow-hidden tw-whitespace-nowrap tw-rounded-md mobile-menu-parent-border"} >
                <div
                    className="tw-flex tw-justify-between tw-w-full tw-text-left"
                    onClick={(e) => { 
                        if ( isEmpty(redirectUrl) ) {
                            e.preventDefault();
                            if ( payload.item?.items ) setMenuStatus(payload.item.id);
                            else {
                                props.handleSidebarToggle();
                            }
                        }
                    }}
                >
                    <div className="tw-flex tw-py-3 tw-px-3 tw-items-center tw-w-full tw-h-full" >
                        {
                            !props.toggleCollapse && (
                                <span className="tw-text-md tw-text-text-light">
                                    <RMDRNavLink
                                        title={languageVersion?.name}
                                        text={languageVersion?.name}

                                        hasNavigationDestination={payload.item.hasDestinationNavigation}
                                        isShowAlways={true}

                                        navigationURL={languageVersion?.contentRouteNavigationURL}

                                        redirectType={payload.item.redirectType}
                                        redirectUrl={redirectUrl?.value}
                                    />
                                </span>
                            )
                        }
                    </div>
                </div>
                
            </div>
        )
    }

    return (
        <div className="tw-py-4 tw-shadow-lg">
            <Listbox value={selected} onChange={setSelected} as="div" className="tw-text-left tw-mx-4 tw-mb-2">
                <div className="rtw-elative mt-2">
                    <ListboxButton className="tw-relative tw-w-full tw-cursor-default tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left tw-text-gray-900 tw-shadow-sm focus:tw-outline-none sm:tw-text-sm/6 tw-border tw-border-solid tw-border-black tw-h-11">
                        <span className="tw-ml-3 tw-block tw-truncate">{selected?.twoLetterISOName.toUpperCase()}</span>
                        <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-ml-3 tw-flex tw-items-center tw-pr-2">
                            <ChevronDownIcon aria-hidden="true" className="tw-size-5 tw-text-gray-400" />
                        </span>
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-56 tw-w-[calc(100%-2rem)] tw-overflow-auto tw-bg-white tw-py-1 tw-text-base tw-shadow-lg focus:tw-outline-none data-[closed]:data-[leave]:tw-opacity-0 data-[leave]:tw-transition data-[leave]:tw-duration-100 data-[leave]:tw-ease-in sm:tw-text-sm"
                    >
                        {
                            props.languagePayload?.map((item) => (
                                <ListboxOption
                                    key={item.id}
                                    value={item}
                                    className="tw-group tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9 tw-text-gray-900 data-[focus]:tw-bg-indigo-600 data-[focus]:tw-text-white"
                                >
                                    <span className="tw-ml-3 tw-block tw-truncate tw-font-normal group-data-[selected]:tw-font-semibold">{item.twoLetterISOName.toUpperCase()}</span>
                                    <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4 tw-text-indigo-600 group-data-[focus]:tw-text-white [.group:not([data-selected])_&]:tw-hidden">
                                        <CheckIcon aria-hidden="true" className="tw-size-5" />
                                    </span>
                                </ListboxOption>
                            ))
                        }
                    </ListboxOptions>
                </div>
            </Listbox>

            <div className="tw-flex tw-border tw-border-solid tw-mx-4">
                <div className="tw-flex tw-px-3 tw-items-center">
                    <i className="fa fa-search fa-lg" />
                </div>

                <input ref={searchInputRef} className="tw-flex-grow tw-w-full tw-border-0 focus:tw-border-0 tw-p-[10px]" type="text" placeholder="Search..." onKeyDown={onKeyDown} />

                <div className="tw-flex tw-px-3 tw-items-center tw-cursor-pointer" onClick={clear} >
                    <i className="fa fa-times-circle fa-xl" />
                </div>
            </div>

            {
                props.navigation.map((item:TClientMenuItemList, index:number) => {
                    return (
                        <div key={`m${index}`} className="tw-mx-4">
                            { <MenuParent item={item} /> }
                            {
                                item?.items && 
                                    <div
                                        className={`tw-grid tw-text-sm tw-text-slate-600 tw-overflow-hidden tw-pl-3 ${menuOpened.includes(item.id) ? 'tw-grid-rows-[1fr] tw-opacity-100' : 'tw-grid-rows-[0fr] tw-opacity-0'}`}
                                    >
                                        <div className="tw-overflow-hidden tw-flex tw-flex-col">
                                            { 
                                                item.items.map((subItem:any, subItemIndex:number) => { 

                                                    return (
                                                        <>
                                                            <MenuParent item={subItem} />
                                                            {
                                                                subItem?.items && 
                                                                    <div
                                                                        className={`tw-grid tw-text-sm tw-text-slate-600 tw-overflow-hidden tw-pl-3 ${menuOpened.find((item:any) => item === subItem.id) ? 'tw-grid-rows-[1fr] tw-opacity-100' : 'tw-grid-rows-[0fr] tw-opacity-0'}`}
                                                                    >
                                                                        <div className="tw-overflow-hidden tw-flex tw-flex-col">
                                                                            { 
                                                                                subItem.items.map((subItem1:any, subItem1Index:number) => { 

                                                                                    return <MenuParent _key={subItem1.id} item={subItem1} />

                                                                                }) 
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                    )

                                                }) 
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    )

                })
            }

        </div>
    );

}

MobileMenuMenuComponent.defaultProps = {

    navigation: [],
    toggleCollapse: false,
    handleSidebarToggle: () => {}

}

const Component = React.memo(MobileMenuMenuComponent, (prevProps, nextProps) => {
    console.log("MobileMenuMenuComponent.React.memo", {prevProps, nextProps});
    return true;
});

export default connector(Component);
