import _SiteSettingsServiceAction from "cms/actions/services/ClientSiteSettingsServiceAction";


const screenCode = "COM";
class SiteSettingsServiceAction extends _SiteSettingsServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);

        this.serviceCode = "SS";
    }

}

export default SiteSettingsServiceAction;
