import React, { useEffect, useState } from "react";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// types
import { TMainPageLayout } from "../type";
// components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EContentType } from "cms/enums/EContentType";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import RMDRImage from "components/RMDRImage";
import { EResourceType } from "cms/enums/EResourceType";
import IResource from "cms/interfaces/IResource";


function mapStateToProps ( state: any ) {
    console.log("MNPG003.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function ItemImageLeft(props: { payload?: ILanguageVersionList, resources?: IResource[] }) {

    return (
        <div className="content-inner">
            <div className="row">
                <div className="col-lg-6">
                    <div className="grey-border text-left">
                        <div className="ttm_single_image-wrapper with-border15">
                            <RMDRImage
                                resourceType={EResourceType.OUTER_IMAGE} 
                                resources={props.resources}
                                alt={props.payload?.name} 
                                className="img-fluid grey-boder"
                                title={props.payload?.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="text-start res-991-mt-30 ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.payload?.value)}} />
                </div>
            </div>
        </div>
    )

}

function ItemImageRight(props: { payload?: ILanguageVersionList, resources?: IResource[] }) {

    return (
        <div className="content-inner">
            <div className="row">
                <div className="col-lg-6">
                    <div className="text-start ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.payload?.value)}} />
                </div>
                <div className="col-lg-6">
                    <div className="grey-border res-991-mt-30 text-left">
                        <div className="ttm_single_image-wrapper with-border15">
                            <RMDRImage
                                resourceType={EResourceType.OUTER_IMAGE} 
                                resources={props.resources}
                                alt={props.payload?.name} 
                                className="img-fluid grey-boder"
                                title={props.payload?.name}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


function MNPG003 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG003.rendered", props);

    const [tabs, setTabs] = useState<any>(null);
    const [tabPanels, setTabPanels] = useState<any>(null);

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    useEffect(
        () => {
            let tabs        = [];
            let tabPanels   = [];
        
            if ( props.childsPayload && !isEmpty(props.childsPayload) ) {
        
                for ( let index = 0; index < props.childsPayload.length; index++ ) {
                    const item = props.childsPayload[index];
                    const childLanguageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                        languageVersions: item.languageVersions,
                        filters: [
                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                        ],
                        type: "find"
                    }); 
        
                    tabs.push(
                        <Tab className="tab" title={childLanguageVersion?.name||""}>
                            <span>
                                <RMDRImage
                                    resourceType={EResourceType.ICON} 
                                    resources={item.resources} 
                                    alt={childLanguageVersion?.name} 
                                    title={childLanguageVersion?.name} 
                                    style={{"marginBottom": 0, "height": "auto"}} 
                                    className="ttm-icon ttm-icon_element-size-lg"
                                />
                                <p>{ childLanguageVersion?.name }</p>
                            </span>
                        </Tab>
                    );
        
                    if ( index % 2 === 0 ) {
                        tabPanels.push(<TabPanel><ItemImageRight payload={childLanguageVersion} resources={item.resources} /></TabPanel>)
                    } else {
                        tabPanels.push(<TabPanel><ItemImageLeft payload={childLanguageVersion} resources={item.resources} /></TabPanel>)
                    }
                }

                setTabs(tabs);
                setTabPanels(tabPanels);
            }
        }, []
    )

    return (
        <section className="ttm-row tab-section ttm-bgcolor-grey clearfix">
            <div className="container-xl">
                
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title text-center with-desc clearfix">
                            <div className="title-header">
                                {
                                    parentLanguageVersion?.value && <h5 className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(parentLanguageVersion.value)}} />
                                }
                                <h2 className="title">{ parentLanguageVersion?.name }</h2>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    tabs && tabPanels &&
                        (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ttm-tabs text-center ttm-tab-style-classic style1">
                                        <Tabs defaultIndex={0}>
                                            <TabList className="tabs">
                                                { tabs }
                                            </TabList>
                                            <div className="box-shadow content-tab">
                                                { tabPanels }
                                            </div>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        )
                }

            </div>
        </section>
    );

}

const Component = React.memo(MNPG003, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG003.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
