import Lodash from 'lodash';


function deleteKeyFromListOfDictionary( value:any[], keys:string[] ) {

    if ( Lodash.isEmpty(value) ) return value;

    const _keys = keys?.filter((item:string) => item);
    if ( Lodash.isEmpty(keys) || Lodash.isEmpty(_keys) ) return value;

    let _value:any[] = [...value];
    _value.map((item:any) => { for(let key of _keys) delete item[key]});

    return _value;

}

export default deleteKeyFromListOfDictionary;
