import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from '@reduxjs/toolkit';
import { isEmpty } from "lodash";
import { v4 as UUIDv4 } from 'uuid';
// components
import RMDRLink from "components/RMDRLink";
import RMDRImage from 'components/RMDRImage';
// types
import { TLatestContents } from "../type";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from 'cms/enums/EContentStatus';
// utilities
import { getDate } from "core/utilities/DateTimeHelper";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
// actions
import ClientPagesServiceAction from "cms/actions/components/LatestContents/ClientPagesServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import IClientPageList from 'cms/interfaces/IClientPageList';
import IClientLanguage from 'core/interfaces/IClientLanguage';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import TValidations from 'cms/types/TValidations';


function mapStateToProps ( state: any ) {
    console.log("LatestContents01.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail   = state.SiteSettingStore.details?.payload;
    const clientPagesGet:any                                    = state.LatestContents.ClientPagesStore.get;
    const clientPagesIsLoading:boolean                          = state.LatestContents.ClientPagesStore.isLoading;
    const clientPagesError:TValidations[]|null|undefined        = state.LatestContents.ClientPagesStore.error;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingsDetailsPayload,

        clientPagesGet,
        clientPagesIsLoading,
        clientPagesError

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listClientPages: ( categoryId:string, webApplicationLanguageCode:string, top:number, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `contentId eq ${categoryId}`],
                orderBy: ["creationDateTime desc"],
                top: top
            };

            clientPagesServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode }, 
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
        },

        // others
        _clean: ( key:string ) => {
            clientPagesServiceAction.getClean(key);
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function Loader () {

    return (
        <div className="widget widget_text clearfix tw-animate-pulse">
            <h3 className="widget-title tw-h-8 tw-w-full tw-bg-gray-100"></h3>
            <ul className="widget-post ttm-recent-post-list">
                <li>
                    <div className="tw-h-16 tw-w-16 tw-bg-gray-100 tw-float-left" />
                    <div>
                        <div className="tw-h-3 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                    </div>
                </li>
                <li>
                    <div className="tw-h-16 tw-w-16 tw-bg-gray-100 tw-float-left" />
                    <div>
                        <div className="tw-h-3 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                    </div>
                </li>
                <li>
                    <div className="tw-h-16 tw-w-16 tw-bg-gray-100 tw-float-left" />
                    <div>
                        <div className="tw-h-3 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                        <div className="tw-h-3 tw-mt-1 tw-ml-20 tw-bg-gray-100" />
                    </div>
                </li>
            </ul>
        </div>
    )

}

function LatestContents01 ( props:TLatestContents<PropsFromRedux> ) {

    const [ payload, setPayload ] = useState<IClientPageList[]>([]);
    const [ key, setKey ] = useState<string>();

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({ 
        languageVersions: props.payload?.languageVersions, 
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ], 
        type: "find"
    });

    useEffect(
        () => {

            if ( !isEmpty(props.payload) && props.payload.isContentChildsMapped === true ) {
                let _key = UUIDv4().split("-")[4];
                setKey(_key);
                props._listClientPages(props.payload.contentId, props.webApplicationLanguageCode, props.top||3, _key);
            }

        }, []
    )

    useEffect(
        () => {

            if ( key && !isEmpty(props.clientPagesGet?.[key]) ) {
                setPayload(props.clientPagesGet?.[key]);
                setTimeout(() => { props._clean(key); }, 100);
            }

        }, [ props.clientPagesGet, key ]
    )

    if ( props.clientPagesIsLoading ) {

        return <Loader />

    } else if ( isEmpty(props.clientPagesError) ) {

        return (
            <div className="widget widget_text clearfix">
                <h3 className="widget-title tw-line-clamp-1">{ languageVersion?.name }</h3>
                <ul className="widget-post ttm-recent-post-list">
                    {
                        payload.map((item) => {
                            
                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: 'find'
                            });
                            
                            const redirectUrl = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                ],
                                type: 'find'
                            });
                            
                            // const postDate = getDate(languageVersion?.creationDateTime); 
    
                            return (
                                <li key={item.id}>
                                    <RMDRLink
                                        text={null}
                                        title={languageVersion?.name}
    
                                        hasNavigationDestination={props.payload.hasDestinationNavigation}
    
                                        navigationURL={languageVersion?.contentRouteNavigationURL}
    
                                        redirectType={props.payload.redirectType}
                                        redirectUrl={redirectUrl?.value}
                                    >
                                        <RMDRImage
                                            resourceType={EResourceType.OUTER_IMAGE} 
                                            resources={item.resources}
                                            alt={languageVersion?.name} 
                                            title={languageVersion?.name}
                                            className="img-fluid"
                                        />
                                    </RMDRLink>
                                    <RMDRLink
                                        text={languageVersion?.name}
                                        title={languageVersion?.name}
    
                                        hasNavigationDestination={props.payload.hasDestinationNavigation}
    
                                        navigationURL={languageVersion?.contentRouteNavigationURL}
    
                                        redirectType={props.payload.redirectType}
                                        redirectUrl={redirectUrl?.value}
                                    />
                                    <div className="ck-content tw-line-clamp-2" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value)}} />
                                    {/* <span className="post-date"><i className="fa fa-calendar" />{ postDate }</span> */}
                                </li>
                            );
    
                        })
                    }
                </ul>
            </div>           
        );

    }

    return null;

}

const Component = React.memo(LatestContents01, ( prevProps:TLatestContents<PropsFromRedux>, nextProps:TLatestContents<PropsFromRedux> ) => {
    console.log("LatestContents01.memo", { prevProps, nextProps });
    return !(
        prevProps.clientPagesIsLoading !== nextProps.clientPagesIsLoading
        || prevProps.clientPagesGet !== nextProps.clientPagesGet 
    );
});

export default connector(Component);

//TODO: Is Content Childs Mapped var ise istek atılacak. Yok ise childlar listelenecek.