// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientSliderItemService from "cms/services/ClientSliderItemService";


class ClientSliderItemServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientSliderItemService, screenCode);
    }

}

export default ClientSliderItemServiceAction;
