enum ESizeType {
    
    X01 = 0,    // 0   - 100
    X02,        // 100 - 200
    X03,        // 200 - 300
    X04,        // 300 - 400
    X05,        // 400 - 500
    X06,        // 500 - 600
    X07,        // 600 - 700
    X08,        // 700 - 800
    X09,        // 800 - 900
    X10,        // 900 - 1000
    X11         // 1000 - y
    
}

export default ESizeType;
