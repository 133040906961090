import ReactGA from 'react-ga4';
import { isEmpty } from "lodash";
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';


var instance:any = null;

class IAnalytics {

    isActive:boolean;

    constructor() {

        this.isActive = false;
        this.init();
    
    }

    init() {

    }

    sendWebVitalsData( metric:any ) {

    }

    sendPageView( page:string, title:string ) {

    }

}

class GoogleAnalytics extends IAnalytics {

    init () {

        if ( process.env.GOOGLE_ANALYTICS && !isEmpty(process.env.GOOGLE_ANALYTICS) ) {
            ReactGA.initialize(process.env.GOOGLE_ANALYTICS);
            this.isActive = true;
        }
        
    }

    sendPageView ( page:string, title:string ) {
        
        if ( this.isActive ) {
            ReactGA.send({ hitType: "pageview", page, title });
        }
    
    }

    sendWebVitalsData ( metric:any ) {
    
        // if ( this.isActive && metric !== null ) {
        //     ReactGA.event({
        //         eventCategory: 'Web Vitals',
        //         eventAction: metric.name,
        //         eventValue: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
        //         eventLabel: metric.id, // id unique to current page load
        //         nonInteraction: true, // avoids affecting bounce rate
        //     });
        // }
    
    }

}

class ApiAnalytics extends IAnalytics {

}

class SerializeAnalytics extends IAnalytics {

    init () {

        this.isActive = true;
    
    }

    sendPageView ( page:string, title:string ) {
    
        console.table({ hitType: "pageview", page, title })
    
    }

    sendWebVitalsData ( metric:any ) {

        console.table(JSON.parse(JSON.stringify(metric)));
    
    }

}

class AnalyticsExtension {

    googleAnalytics:GoogleAnalytics;
    apiAnalytics:ApiAnalytics;
    serializeAnalytics:SerializeAnalytics;

    constructor() {

        this.googleAnalytics = new GoogleAnalytics();
        this.apiAnalytics = new ApiAnalytics();
        this.serializeAnalytics = new SerializeAnalytics();

        console.log("test:", process.env);

        this.webVitals();
    
    }

    initGoogleAnalytics () {
    
        this.googleAnalytics.init();
    
    }

    initApiAnalytics () {
    
        this.apiAnalytics.init()
    
    }

    initSerializeAnalytics () {
    
        this.serializeAnalytics.init()
    
    }

    sendWebVitals ( metric:any ) {
    
        this.googleAnalytics.sendWebVitalsData(metric);
        this.apiAnalytics.sendWebVitalsData(metric);
        this.serializeAnalytics.sendWebVitalsData(metric);
    
    }

    sendPageView ( page:string, title:string) {
    
        this.googleAnalytics.sendPageView( page, title );
        this.apiAnalytics.sendPageView( page, title );
        this.serializeAnalytics.sendPageView( page, title );
    
    }

    send ( webVitalMetric:any, page:string, title:string ) {
    
        this.sendWebVitals(webVitalMetric);
        this.sendPageView( page, title );
    
    }

    webVitals () {
    
        onCLS(this.sendWebVitals.bind(this));
        onFCP(this.sendWebVitals.bind(this));
        onFID(this.sendWebVitals.bind(this));
        onINP(this.sendWebVitals.bind(this));
        onLCP(this.sendWebVitals.bind(this));
        onTTFB(this.sendWebVitals.bind(this));
    
    }

}

if ( !instance ) instance = new AnalyticsExtension();

export default instance;
