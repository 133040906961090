import { combineReducers } from 'redux';

// reducers
import ContactUsServiceStore from "cms/reducers/services/ContactUsServiceStore";



const screenCode = "202";
const INNR003Store = combineReducers({
 
    ContactUsStore: ContactUsServiceStore({ screenCode }),

});

export default INNR003Store;
