import { isEmpty } from 'lodash';
import IClientMenuItemList from "cms/interfaces/IClientMenuItemList";
import TMenu from 'cms/types/TMenu';


function MenuComposer ( value:IClientMenuItemList[]|null, parentMenuItems:IClientMenuItemList[]|undefined|null) {

    let _value:TMenu[] = [];

    if ( value && parentMenuItems && !isEmpty(value) && !isEmpty(parentMenuItems) ) {

        for ( let parentMenuItem of parentMenuItems ) {
            let _parentMenuItem:TMenu = {...parentMenuItem};
    
            let childMenuItems = value.filter((item:any) => item.parentId === parentMenuItem.id);
            if ( childMenuItems.length > 0 ) {
                    let response = MenuComposer(value, childMenuItems);
                    if ( response.length > 0 ) {
                        _value.push({ ..._parentMenuItem, items: response });
                    }
            }
            else {
                _value.push(_parentMenuItem);
            }
        }

    }

    return _value;

}

export default MenuComposer;
