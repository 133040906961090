// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientContentsService from "cms/services/ClientContentsService";


class ClientContentsServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientContentsService, screenCode);
    }

}

export default ClientContentsServiceAction;
