import InnerLayouts from "components/InnerLayouts";
import ShowroomLayouts from "components/ShowroomLayouts";
import GalleryLayouts from "components/GalleryLayouts";

const Layouts = {

    ...InnerLayouts,
    ...ShowroomLayouts,
    ...GalleryLayouts
    
}

export default Layouts;