enum EMessageStatus {

    SUCCESS = "MS",
    ERROR   = "ME",
    WARNING = "MW",
    INFO    = "MI",
    POPUP   = "MP"

}

export default EMessageStatus;
