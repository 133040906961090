import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";
// actions
import CoreAction from "core/actions/common/CoreAction";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { Dispatch } from "@reduxjs/toolkit";


function mapStateToProps ( state: any ) {
    console.log("LanguageChooser.mapStateToProps: ", state);

    const languagePayload:IClientLanguage[] = state.LanguageStore.list?.payload;

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    return {

        languagePayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("LanguageChooser.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {

        _showLoader: () => { 
            coreAction.showLoader(); 
        },

        _setWebApplicationLanguage: ( value:any ) => {
            coreAction.setWebApplicationLanguage(value);
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TLanguageChooser<T> = {

} & T;


/* component */
function ContentLoader () {

    return (
        <></>
    )

}

function LanguageChooser ( props:TLanguageChooser<PropsFromRedux> ) {
    console.log("LanguageChooser.rendered", props)

    const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    function toggleLanguageOverlay ( event:React.MouseEvent<HTMLDivElement, MouseEvent> ) {

        setShowLanguageOverlay(!showLanguageOverlay);
    
    }

    function reloadPage( webApplicationLanguageISOCode:string ) {
        
        try {
            let pathnameArray = location.pathname?.split("/")?.filter((item:any) => item);
            let newPathname:string = ""; 
    
            if ( pathnameArray.length > 0 ) {
                pathnameArray[0] = webApplicationLanguageISOCode;
                newPathname = `/${pathnameArray.join("/")}`;
            }
    
            navigate(newPathname, { replace: true });
            window.location.reload(); 
        } catch( ex ) {
            console.error(ex);
        }

    }

    function setLanguage( webApplicationLanguage:any ) {

        props._showLoader();
        reloadPage( webApplicationLanguage.twoLetterISOName );

    }

    return (
        <li className="ttm-header-language-link ttm-bgcolor-darkgrey ttm-textcolor-white tw-cursor-pointer">
            <div onClick={toggleLanguageOverlay}>
                {
                    props.webApplicationLanguageISOCode?.toUpperCase() || "."
                }
                <span>{ props?.languagePayload?.length || 0 }</span>
            </div>
            {
                showLanguageOverlay && 
                    <div className="ttm-language-overlay">
                        <ul>
                            {
                                props?.languagePayload?.map((item:any, index:number) => (<li onClick={(event:any) => { setLanguage(item); }}>{item.twoLetterISOName.toUpperCase()}</li>))
                            }
                        </ul>
                    </div>
            }
        </li>
    )
    
}

const Component = React.memo(LanguageChooser, ( prevProps:TLanguageChooser<PropsFromRedux>, nextProps:TLanguageChooser<PropsFromRedux> ) => {
    console.log("LanguageChooser.memo", { prevProps, nextProps });
    return !( 
        prevProps.languagePayload !== nextProps.languagePayload 
        || prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode 
    )
});

export default connector(Component);
