import { isEmpty } from 'lodash';
import CoreDefault from "core/Defaults";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ApplicationDocumentSelector from './ApplicationDocumentSelector';


function PutManifestFileDynamic( document: any, siteSettingPayload:IClientSiteSettingDetail ) {

    let dynamicManifest = {
        name: "",
        short_name: "",
        start_url: document.location.origin,
        display: "standalone",
        background_color: "#ffffff",
        theme_color: "#ffffff",
        description: "",
        icons: []
    }

    // icon
    if ( siteSettingPayload.resources && !isEmpty(siteSettingPayload.resources) ) {

        let logo_x01 = ApplicationDocumentSelector({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X01
        });
        if ( logo_x01 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x01.filePath}`,
                "type": `image/webp`,
                "sizes": "64x64"
            } as never);
        }

        let logo_x02 = ApplicationDocumentSelector({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X02
        });
        if ( logo_x02 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x02.filePath}`,
                "type": `image/webp`,
                "sizes": "128x128"
            } as never);
        }

        let logo_x03 = ApplicationDocumentSelector({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X03
        });
        if ( logo_x03 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x03.filePath}`,
                "type": `image/webp`,
                "sizes": "256x256"
            } as never);
        }

        let logo_x06 = ApplicationDocumentSelector({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X06
        });
        if ( logo_x06 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x06.filePath}`,
                "type": `image/webp`,
                "sizes": "512x512"
            } as never);
        }

    }

    // language version
    const languageVersion = siteSettingPayload.languageVersions?.find((item) => item.view === EView.OUTTER && item.viewType === EViewType.NAME_DESCRIPTION);
    const description = siteSettingPayload.languageVersions?.find((item) => item.view === EView.META_DATA && item.viewType === EViewType.TEXT && item.name === "metaDescription")?.value||"";

    dynamicManifest["name"] = languageVersion?.name||"";
    dynamicManifest["short_name"] = languageVersion?.name||"";
    dynamicManifest["description"] = description;

    // 
    const stringManifest = JSON.stringify(dynamicManifest);
    const blobManifest = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blobManifest);
    document?.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

}

export default PutManifestFileDynamic;
