import Lodash from 'lodash';
import Defaults from 'core/Defaults';
import IClientLanguage from 'core/interfaces/IClientLanguage';


class DetectLanguage {

    languages:IClientLanguage[]|null             = null;
    webApplicationLanguage?:IClientLanguage|null = null;
    webApplicationLanguageISOCode?:string|null    = null;
    webApplicationLanguageCode?:string|null       = null;

    constructor( languages: IClientLanguage[]|null ) {

        this.languages = languages;

    }

    _findLanguage ( languages:any, ISOCode:string ) {

        if ( Lodash.isEmpty(ISOCode) ) return null;
    
        let language = languages.find((item:any) => item.twoLetterISOName === ISOCode);
        if (!Lodash.isEmpty(language)) delete language["id"];
    
        return language;
    
    }

    _fromLocation() {

        if ( !Lodash.isEmpty(this.webApplicationLanguageISOCode) ) return;

        try {
            
            const locationPathnameArray:any = window.location.pathname;
            if ( !Lodash.isEmpty(locationPathnameArray) ) {
                const WebApplicationLanguageISOCodeFromLocation = locationPathnameArray.split("/").filter((n:any) => n)?.[0];
                this.webApplicationLanguage         = this._findLanguage(this.languages, WebApplicationLanguageISOCodeFromLocation);
                this.webApplicationLanguageISOCode  = this.webApplicationLanguage?.twoLetterISOName;
                this.webApplicationLanguageCode     = this.webApplicationLanguage?.code;
            }

        } catch( ex ) {
            
            this.webApplicationLanguage         = null;
            this.webApplicationLanguageISOCode  = null;
            this.webApplicationLanguageCode     = null;
        
        }

    }

    _fromLocalStorage() {

        if ( !Lodash.isEmpty(this.webApplicationLanguageISOCode) ) return;

        try {

            const webApplicationLanguageFromLocalStorage = localStorage.getItem("WebApplicationLanguage");
            if ( !Lodash.isEmpty(webApplicationLanguageFromLocalStorage) ) {
                this.webApplicationLanguage         = JSON.parse(webApplicationLanguageFromLocalStorage as string);
                this.webApplicationLanguageISOCode  = this.webApplicationLanguage?.twoLetterISOName;
                this.webApplicationLanguageCode     = this.webApplicationLanguage?.code;
            }

        } catch( ex ) {

            this.webApplicationLanguage         = null;
            this.webApplicationLanguageISOCode  = null;
            this.webApplicationLanguageCode     = null;
        
        }

    }

    _fromNavigator() {

        if ( !Lodash.isEmpty(this.webApplicationLanguageISOCode) ) return;

        try {

            const WebApplicationLanguageISOCodeFromNavigator:any = navigator.languages?.filter((item:string) => item.length === 2);
            for ( let item of WebApplicationLanguageISOCodeFromNavigator ) {
                let webApplicationLangaugeFromNavigator = this._findLanguage(this.languages, item);
                if ( webApplicationLangaugeFromNavigator ) {
                    this.webApplicationLanguage         = webApplicationLangaugeFromNavigator;
                    this.webApplicationLanguageISOCode  = webApplicationLangaugeFromNavigator.twoLetterISOName;
                    this.webApplicationLanguageCode     = webApplicationLangaugeFromNavigator.code;
                    break;
                }
            }

        } catch( ex ) {
            
            this.webApplicationLanguage         = null;
            this.webApplicationLanguageISOCode  = null;
            this.webApplicationLanguageCode     = null;
        
        }

    }

    _fromDefault() {

        if ( !Lodash.isEmpty(this.webApplicationLanguageISOCode) ) return;

        try {

            const webApplicationLanguageFromDefaults = this._findLanguage(this.languages, Defaults.clientLanguage.twoLetterISOName);
            this.webApplicationLanguage         = webApplicationLanguageFromDefaults;
            this.webApplicationLanguageISOCode  = webApplicationLanguageFromDefaults.twoLetterISOName;
            this.webApplicationLanguageCode     = webApplicationLanguageFromDefaults.code;
        
        } catch( ex ) {

            this.webApplicationLanguage         = null;
            this.webApplicationLanguageISOCode  = null;
            this.webApplicationLanguageCode     = null;

        }
    }

    _fromFirst () {

        if ( !Lodash.isEmpty(this.webApplicationLanguageISOCode) ) return;

        try {

            const webApplicationLanguageFirst = this.languages?.[0];
            this.webApplicationLanguage         = webApplicationLanguageFirst;
            this.webApplicationLanguageISOCode  = webApplicationLanguageFirst?.twoLetterISOName;
            this.webApplicationLanguageCode     = webApplicationLanguageFirst?.code;
        
        } catch( ex ) {

            this.webApplicationLanguage         = null;
            this.webApplicationLanguageISOCode  = null;
            this.webApplicationLanguageCode     = null;

        }

    }

    getLangauge() {

        if ( Lodash.isEmpty(this.languages) ) {
            return { 
                webApplicationLanguage: null,
                WebApplicationLanguageISOCode: null,
                webApplicationLanguageCode: null,
            }
        }

        this._fromLocation();
        this._fromLocalStorage();   // TODO: Burada client-languages dan gelene veriler ile eşleşiyor mu bakılacak. eğer local de en varsa ve sunucu da en yok ise seçmeyecek.
        this._fromNavigator();
        this._fromDefault();
        this._fromFirst();

        localStorage.setItem("WebApplicationLanguage", JSON.stringify(this.webApplicationLanguage));
        localStorage.setItem("WebApplicationLanguageISOCode", this.webApplicationLanguageISOCode||"");
        localStorage.setItem("webApplicationLanguageCode", this.webApplicationLanguageCode||"");

        return { 
            webApplicationLanguage: this.webApplicationLanguage, 
            webApplicationLanguageISOCode: this.webApplicationLanguageISOCode, 
            webApplicationLanguageCode: this.webApplicationLanguageCode 
        }

    }

}

const detectLanguage = ( languages:IClientLanguage[]|null ) => new DetectLanguage(languages).getLangauge();

export default detectLanguage;
