import EHttpStatusCode from "core/enums/EHttpStatusCode";
import EEndpointProtocol from "core/enums/EEndpointProtocol";

const CoreDefault = {

    url: `${process.env.PROTOCOL}${process.env.HOSTNAME||window.location.hostname}${process.env.PORT ? `:${process.env.PORT}`:""}`,
    api: `${process.env.PROTOCOL}${process.env.API_HOSTNAME||window.location.hostname}${process.env.API_PORT ? `:${process.env.API_PORT}`:""}`,
	cdn: `${process.env.PROTOCOL}${process.env.CDN_HOSTNAME||window.location.hostname}${process.env.CDN_PORT ? `:${process.env.CDN_PORT}`:""}`,

    acceptableHttpStatus: [
        EHttpStatusCode.OK,
        EHttpStatusCode.CREATED,
        EHttpStatusCode.ACCEPTED,
        EHttpStatusCode.BAD_REQUEST,
        EHttpStatusCode.UNAUTHORIZED,
        EHttpStatusCode.NOT_ACCEPTABLE,
        EHttpStatusCode.INTERNAL_SERVER_ERROR
    ],
    fetchSettings: {
        protocol: EEndpointProtocol.MVB_API
    },
    tokenKeyName: "token",
    clientLanguage: {
        code: "tr-TR",
        name: "Turkish",
        twoLetterISOName: "tr"
    }
    
};

export default CoreDefault;
