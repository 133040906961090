import React from "react";
// components
import RMDRLink from "components/RMDRLink";
// types
import { TSLDRLatout } from "../type";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// interfaces
import RMDRImage from "components/RMDRImage";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function SLDR_001 ( props: TSLDRLatout ) {
    console.log("SLDR_001: ", props);
    
    const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.SLIDER_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: 'find'
    });

    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|undefined>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
        ],
        type: 'find'
    });

    const readMoreString = LanguageVersionSelector<ILanguageVersionList|undefined>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "readMoreString" }
        ],
        type: 'find'
    });

    return (
        <div className="armador-banner-item">
            <div className="armador-view">
                
                <RMDRImage
                    resourceType={EResourceType.PATTERN} 
                    resources={props.payload.resources}
                    alt={languageVersion?.name} 
                    title={languageVersion?.name} 
                    className="d-block w-100"
                    // disableLazyLoading={props.index === 0}

                    width={"100%"}
                    height={"100%"}
                />
        
            </div>
            
            <div className="armador-carousel-caption">
                <div className="container-xl d-flex">
                    <div className="carousel-caption-inner tw-bg-white tw-bg-opacity-80 tw-p-5 tw-rounded-lg">
                        <h5 className="ttm-textcolor-skincolor">{languageVersion?.name}</h5>
                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value)}} />
                        {
                            props.payload.hasDestinationNavigation && (
                                <p>
                                    <RMDRLink
                                        title={languageVersion?.name}
                                        text={readMoreString?.value}

                                        hasNavigationDestination={props.payload.hasDestinationNavigation}

                                        navigationURL={languageVersion?.contentRouteNavigationURL}

                                        redirectType={props.payload.redirectType}
                                        redirectUrl={redirectUrl?.value}

                                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white me-3"
                                    />
                                </p> 
                            )
                        }
                    </div>
                    <div className="d-inline-block effect position-relative">
                        <RMDRImage
                            resourceType={EResourceType.OUTER_IMAGE} 
                            resources={props.payload.resources}
                            alt={languageVersion?.name}
                            title={languageVersion?.name}
                            className="img-fluid"
                            // disableLazyLoading={props.index === 0}

                            width={"100%"}
                            height={"100%"}
                        />
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default SLDR_001;
