import { combineReducers } from 'redux';
// reducers
import ClientContentsServiceStore from "cms/reducers/services/ClientContentsServiceStore";
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";
import ContentMetricsServiceStore from "core/reducers/service/metrics/ContentMetricsServiceStore";

const screenCode = "20B";

const ContentStore = combineReducers({
 
    ClientContentsStore: ClientContentsServiceStore({ screenCode }),
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),
    ContentMetricsStore: ContentMetricsServiceStore({ screenCode })
    
});

export default ContentStore;
