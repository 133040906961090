import EActionType from 'core/enums/EActionType';
import EMessageStatus from 'core/enums/EMessageStatus';
//
import IAction from 'core/interfaces/IAction';
//
import { toast } from 'react-toastify';
/**
 * - Uygulama içinde herhangi bir sayfada natification mesajı gösterir.
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode ve serviceCode verisi empty olarak set edilmiştir.
 */

function MessagePublisherStore (state:object = {}, action:IAction) {

    switch (action.type) {
        // success
        case `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.SUCCESS}`:
            toast.success(action?.payload, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            
            return state;

        // error
        case `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.ERROR}`:
            toast.error(action?.payload, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return state;

        // warning
        case `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.WARNING}`:
            toast.warn(action?.payload, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return state;

        // info
        case `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.INFO}`:
            toast.info(action?.payload, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return state;
        
        // popup message
        case `${EActionType.PUBLISH_MESSAGE}${EMessageStatus.POPUP}`:
            // TODO: Popup Message
            return state;

        // default
        default:
            return state;
    }

}

export default MessagePublisherStore;
