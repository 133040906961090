// action
import OdataCoreActionBase from "./OdataCoreActionBase";
// enum
import EActionStatus from "core/enums/EActionStatus";
import EActionType from "core/enums/EActionType";
// interface
import { IActionDetailsOdata } from "core/interfaces/IActionDetails";
import IActionRunParams from "core/interfaces/IActionRunParams";


class OdataReadOnlyActionBase extends OdataCoreActionBase {

    async details( params: IActionDetailsOdata ) {

        this.fetching(EActionType.DETAILS);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.DETAILS
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.details.bind(this.service), serviceParams );

    }

    detailsClean () {
    
        this.clean(EActionType.DETAILS);
    
    }

}

export default OdataReadOnlyActionBase;
