import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/screens/Content/ClientPagesServiceAction"
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientPageList from "cms/interfaces/IClientPageList";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { TSHWRMLayout } from "../type";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
import RMDRImage from "components/RMDRImage";
import { Link } from "react-router-dom";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("SHWRM005.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const pageListPayload:IClientPageList[]|null    = state.ShowroomLayoutsStore.ClientPagesStore.list?.payload;
    const pageListError:TValidations[]|null         = state.ShowroomLayoutsStore.ClientPagesStore.list?.error;
    const pageListIsLoading:boolean                 = state.ShowroomLayoutsStore.ClientPagesStore.list?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        pageListPayload,
        pageListError,
        pageListIsLoading,

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listPages: ( contentId:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`contentId eq ${contentId}`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 100
            };

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
        }
    }
    
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <></>
    )

}

function SHWRM005 ( props:TSHWRMLayout<PropsFromRedux> ) {
    console.log("SHWRM005.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    useEffect(
        () => {

            props._listPages( props.contentDetailPayload.id, props.webApplicationLanguageCode );

            return function cleanup () {
                props._showLoader();
                props._clean();
            }
            
        }, []
    )

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

        }, [ props.pageListPayload ]
    )

    return (
        <div className="site-main">
            
            <ContentHeaderRow 
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload.resources}
                resourceType={EResourceType.PARALLAX}
            />
            
            <section className="ttm-row res-991-pt-0 clearfix">
                <div className="container-xl">
                    <div className="row slick_slider ttm-boxes-spacing-10px">

                        {
                            props.pageListPayload?.map((item) => {
                        
                                const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                    languageVersions: item.languageVersions,
                                    filters: [
                                        { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                    ], 
                                    type: "find"
                                });

                                return (
                                    <div key={item.id} className="ttm-box-col-wrapper" style={{ "width": "33%" }}>
                                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                                            <div className="featured-thumbnail">
                                                <RMDRImage
                                                    resourceType={EResourceType.OUTER_IMAGE} 
                                                    resources={item.resources} 
                                                    alt={languageVersion?.name} 
                                                    title={languageVersion?.name} 
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="ttm-box-view-overlay">
                                                <div className="featured-iconbox ttm-media-link">
                                                    {/* <a onClick={() => setState({ isOpen: true })} className="ttm_image"><i className="ti ti-search" /></a>
                                                    <a href={process.env.PUBLIC_URL + '/Single_style1'} className="ttm_link"><i className="ti ti-link" /></a> */}
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5><Link to={languageVersion?.contentRouteNavigationURL||""}>{languageVersion?.name}</Link></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        }

                    </div>
                </div>
            </section>

        </div>            
    )
}

const Component = React.memo(SHWRM005, ( prevProps:TSHWRMLayout<PropsFromRedux>, nextProps:TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM005.memo", { prevProps, nextProps });
    return !(prevProps.pageListPayload !== nextProps.pageListPayload);
});

export default connector(Component);
