import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from "react-redux"
import { isEmpty } from "lodash";
import { Dispatch } from '@reduxjs/toolkit';
// components
import TopBar from './components/TopBar';
import MiddleBar from './components/MiddleBar';
import NavBar from './components/NavBar';
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientMenuItemServiceAction from "cms/actions/components/Header/ClientMenuItemServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import IClientLangauge from 'core/interfaces/IClientLanguage';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
// utilities
import MenuComposer from 'cms/utilities/MenuComposer';
// types
import TValidations from 'cms/types/TValidations';
// style
import "./style.scss";
import { EContentStatus } from 'cms/enums/EContentStatus';
import TMenu from 'cms/types/TMenu';
import IClientMenuItemList from 'cms/interfaces/IClientMenuItemList';


function mapStateToProps ( state: any ) {
    console.log("Header.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLangauge    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const clientMenuItemGetPayload:IClientMenuItemList[]        = state.HeaderStore.ClientMenuItemStore.get?.["MNGRP001"];
    const clientMenuItemGetError:TValidations[]|null            = state.HeaderStore.ClientMenuItemStore.get?.error;
    const clientMenuItemGetIsLoading:boolean                    = state.HeaderStore.ClientMenuItemStore.get?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        clientMenuItemGetPayload,
        clientMenuItemGetError,
        clientMenuItemGetIsLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const clientMenuItemServiceAction = new ClientMenuItemServiceAction(dispatch);

    return {
        // list
        _getClientMenuItems: ( webApplicationLanguageCode:string, mapLayoutName:string, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };
            
            clientMenuItemServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
        },

        // other
        _hideLoader: () => { 
            coreAction.hideLoader(); 
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type THeader<T> = {

} & T;


/* component */
function Loader () {

    return (
        <></>
    )

}

function Header ( props:THeader<PropsFromRedux> ) {
    console.log("Header.rendered:", props);

    const [ navigation, setNavigation ] = useState<TMenu[]>([]);

    useEffect(
        () => {

            if ( !isEmpty(props.webApplicationLanguageCode)) {
                props._getClientMenuItems(props.webApplicationLanguageCode, "MNGRP_001", "MNGRP001");
            }

        }, [ props.webApplicationLanguageCode ]
    )

    useEffect(
        () => {

            if ( !isEmpty(props.clientMenuItemGetPayload) ) {
                let parentMenuItems = props.clientMenuItemGetPayload.filter((item:any) => item.parentId === null);
                let _navigation = MenuComposer(props.clientMenuItemGetPayload, parentMenuItems);
                setNavigation(_navigation);
            }

        }, [ props.clientMenuItemGetPayload ]
    )

    if ( props.clientMenuItemGetIsLoading ) {

        return <Loader />;

    } else if ( isEmpty(props.clientMenuItemGetError) && !isEmpty(props.clientMenuItemGetPayload) ) {

        return (
            <header className="header ttm-header-style-02">
    
                <TopBar
                    siteSettingPayload={props.siteSettingDetailPayload}
                />
    
                <div className="ttm-header-wrap">
    
                    <MiddleBar
                        siteSettingPayload={props.siteSettingDetailPayload}
                    />
                    
                    <NavBar
                        webApplicationLanguage={props.webApplicationLanguage}
                        webApplicationLanguageCode={props.webApplicationLanguageCode}
                        webApplicationLanguageISOCode={props.webApplicationLanguageISOCode}
                        navigation={navigation} 
                    />
                    
                </div>
                
            </header>
        )

    }

    return null;

}

const Component = React.memo(Header, ( prevProps:THeader<PropsFromRedux>, nextProps:THeader<PropsFromRedux> ) => {
    console.log("Header.memo", { prevProps, nextProps });
    return !( 
        prevProps.webApplicationLanguage !== nextProps.webApplicationLanguage
        || prevProps.clientMenuItemGetPayload !== nextProps.clientMenuItemGetPayload 
    );
});

export default connector(Component);
