import _LanguageServiceAction from "core/actions/services/language/LanguageServiceAction";


const screenCode = "COM";
class LanguageServiceAction extends _LanguageServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);

        this.serviceCode = "Lang";
    }

}

export default LanguageServiceAction;