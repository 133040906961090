import IClientLanguage from 'core/interfaces/IClientLanguage';
import EActionType from "core/enums/EActionType";
import ActionCore from 'core/actions/ActionCore';


const screenCode = "COM";
class CoreAction extends ActionCore {

    constructor( dispatch:any ) {
        super( dispatch, "", screenCode);

        this.serviceCode = "Core";
    }

    /**
     * set web application language
     */
    setWebApplicationLanguage ( language: IClientLanguage ) {

        localStorage.setItem("WebApplicationLanguage", JSON.stringify(language));
        localStorage.setItem("WebApplicationLanguageISOCode", language.twoLetterISOName);

        this.store(EActionType.LANGUAGE, language);
        this.store(EActionType.LANGUAGE_CODE, language.twoLetterISOName);
    
    }

    /**
     * show full screen loader
     */
    showLoader () {

        this.clean(EActionType.LOADER)

    }

    /**
     * hide full screen loader
     */
    hideLoader () {
        this.fetching(EActionType.LOADER);

    }

    /**
     * change color palette
     */
    changeColorPalette( payload:any ) {

        localStorage.setItem("color-palette", payload);
        this.store(EActionType.COLOR_PALETTE, payload);

    }

    /**
     * theme mode
     */
    changeColorPaletteMode( payload:any ) {
        
        localStorage.setItem("color-palette-mode", payload);
        this.store(EActionType.COLOR_PALETTE_MODE, payload);

    }

}

export default CoreAction;
