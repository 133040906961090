function getLocalDateTime( isEnd:boolean = false ) {

    let localDateTime = (new Date()).toJSON();
    
    try {

        if ( isEnd ) return `${getDate(localDateTime)}T23:59:59Z`;
        return `${getDate(localDateTime)}T00:00:00Z`;

    } catch (e) {  }
    
}

function getDate( dateTime?:string ) {

    if ( dateTime ) {
        let val = dateTime.split("T");
        return val[0];
    }

}

function _getTime( dateTime?:string ) {

    if ( dateTime ) {
        let val = dateTime.split("T");
        return val[1];
    }

}

function dataTimeToHumanReadable ( datetime?:string ) {

    console.log("dataTimeToHumanReadable: ", datetime);

    if ( datetime ) {

        try {
            let splittedDateTime = new Date(Date.parse(datetime)).toString().split(" ")
            return { day: splittedDateTime[2], month: splittedDateTime[1], year: splittedDateTime[3] }
        } catch(ex) {
            return null;
        }
        

    }

}

export {

    getLocalDateTime,
    getDate,
    dataTimeToHumanReadable

}